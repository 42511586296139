import {
  EnumerationsApi,
  FollowUpApi,
  NotificationsApi,
  ProjectNotificationsApi,
  ProjectsApi,
  UsersApi,
} from '../build/generated-sources/openapi';
import globalAxios from 'axios';

export const useProjectsApi = () => {
  const openApi = new ProjectsApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};

export const useUsersApi = () => {
  const openApi = new UsersApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};

export const useProjectNotificationsApi = () => {
  const openApi = new ProjectNotificationsApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};

export const useNotificationsApi = () => {
  const openApi = new NotificationsApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};

export const useEnumerationsApi = () => {
  const openApi = new EnumerationsApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};

export const useFollowUpsApi = () => {
  const openApi = new FollowUpApi({
    isJsonMime(mime: string): boolean {
      return true;
    },
  }, process.env.REACT_APP_PROJECT_DATABASE_KK_APP_URL, globalAxios);
  return openApi;
};
