import { createBrowserRouter } from 'react-router-dom';
import './styles.css';
import React from 'react';
import { ProtectedLayout } from './components/ProtectedLayout';
import ErrorPage from './pages/ErrorPages/ServerError/ErrorPage';
import { UsersPage } from './pages/UsersPage/UsersPage';
import { ProjectsPage } from './pages/ProjectsPage/ProjectsPage';
import { ProjectCardCreate } from './pages/ProjectCard/ProjectCardCreate';
import { ProjectCardDetail } from './pages/ProjectCard/ProjectCardDetail';
import { RolesAuthRoute } from './core/auth/RolesAuthRoute';
import UserRole from './core/auth/UserRole';
import { NotificationsSettings } from './pages/NotificationsSettings/NotificationsSettings';
import NotFoundPage from './pages/ErrorPages/NotFound/NotFoundPage';

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <ProtectedLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <RolesAuthRoute
            roles={[UserRole.SYSADMIN, UserRole.MANAGER, UserRole.SUPERUSER, UserRole.USER]}
            navigatePath={'/moje-projekty'}/>,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/',
              element: <ProjectsPage filterMyProjects={false} />,
            },
          ],
        },
        {
          path: '/projekty',
          element: <RolesAuthRoute
            roles={[UserRole.SYSADMIN, UserRole.MANAGER, UserRole.SUPERUSER, UserRole.USER]}
            navigatePath={'/moje-projekty'}/>,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/projekty',
              element: <ProjectsPage filterMyProjects={false} />,
            },
          ],
        },
        {
          path: '/projekty/novy',
          element: <ProjectCardCreate />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/projekty/:id',
          element: <ProjectCardDetail />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/moje-projekty',
          element: <ProjectsPage filterMyProjects={true} />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/uzivatele',
          element: <RolesAuthRoute roles={[UserRole.SYSADMIN, UserRole.MANAGER]} />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/uzivatele',
              element: <UsersPage />,
            },
          ],
        },
        {
          path: '/nastaveni',
          element: <RolesAuthRoute roles={[UserRole.SYSADMIN, UserRole.MANAGER]} />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/nastaveni',
              element: <NotificationsSettings />,
            },
          ],
        },
        {
          path: '*',
          element: <RolesAuthRoute roles={[UserRole.SYSADMIN, UserRole.MANAGER,
            UserRole.SUPERUSER, UserRole.USER, UserRole.EXTERNAL_USER]} />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ],
    },
  ],
);
