import { Title } from '@mantine/core';
import { ProjectCard } from './ProjectCard';

export const ProjectCardCreate = () => {
  return (
    <>
      <Title c={'#322886'}>Nový projekt</Title>
      <ProjectCard/>
    </>
  );
};
