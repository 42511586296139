import { forwardRef } from 'react';
import cx from 'clsx';
import { Box, Center, MantineLoaderComponent } from '@mantine/core';
import classes from './CssLoader.module.css';

export const CssLoader: MantineLoaderComponent = forwardRef(({ className, ...others }, ref) => (
  <Center h={800}>
    <Box component="span" className={cx(classes.loader, className)} {...others} ref={ref}/>
  </Center>
));
