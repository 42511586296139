import { rem, ThemeIcon } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import React from 'react';
import { DateInput } from '@mantine/dates';
import classes from '../../../core/style/PrintStyles.module.css';

interface CustomDateInputProps {
  label: string;
  inputProps: any;
  required?: boolean;
  disabled?: boolean;
  withTime?: boolean;
  clearable?: boolean;
  minDate?: Date;
  mt?: number;
  ml?: number;
}

export function CustomDateInput({
  label,
  inputProps,
  required,
  disabled,
  withTime,
  clearable,
  minDate,
  mt,
  ml,
}: CustomDateInputProps) {
  return (
    <DateInput
      {...inputProps}
      label={label}
      withAsterisk={required}
      id={'print-input-field'}
      disabled={disabled}
      clearable={clearable}
      pointer={true}
      mt={mt ?? 10}
      ml={ml ?? 0}
      radius={10}
      classNames={{
        section: classes.section,
        input: classes.input,
      }}
      leftSection={
        <ThemeIcon color={'dpkk-blue'} variant={'light'}>
          <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5}/>
        </ThemeIcon>
      }
      leftSectionWidth={40}
      valueFormat={withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
      minDate={minDate}
    />
  );
}
