import { TextInput } from '@mantine/core';
import React from 'react';
import classes from '../../../core/style/PrintStyles.module.css';
import { CustomTextInputProps } from './CustomTextInput.model';

export function CustomTextInput({ label, inputProps, required = false, disabled, mt }: CustomTextInputProps) {
  return (
    <TextInput
      {...inputProps}
      label={label}
      withAsterisk={required}
      id={'print-input-field'}
      disabled={disabled}
      mt={mt || 10}
      radius={10}
      classNames={{
        input: classes.input,
      }}
    />
  );
}
