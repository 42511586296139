import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProjectsProps, UserSelectType } from './Projects.model';
import { useEnumerationsApi, useFollowUpsApi, useProjectsApi, useUsersApi } from '../../service/Api.service';
import { MRT_ColumnDef } from 'material-react-table';
import {
  getProjectIdsParameter,
  getProjectsTableFiltersParameter,
  getProjectsTablePaginationParameter,
} from '../../core/util/Util';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { TableChangesProps, TableData } from '../../components/MaterialTable/MaterialTable.model';
import { getProjectsColumns } from './ProjectsColumns';
import { ProjectsOverview } from '../../components/ProjectsOverview/ProjectsOverview';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Space, Text } from '@mantine/core';
import { AuthContext } from '../../core/auth/AuthContextProvider';
import { EnumerationDto, FollowUpsDto } from '../../build/generated-sources/openapi';
import handleErrors from '../../core/error-handler/ErrorHandler';
import { modals } from '@mantine/modals';
import { ExportMode } from '../../components/MaterialTable/ExportMenu';

const defaultProjectTableChanges = {
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  sorting: [],
  columnFilters: [],
  globalFilter: '',
};

export const ProjectsPage = ({
  filterMyProjects,
}: ProjectsProps) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [usersList, setUsersList] = useState<UserSelectType[]>([]);
  const [projectsTableData, setProjectsTableData] = useState<TableData>({ data: [], totalPages: 1, totalElements: 0 });
  const [projectTableChanges, setProjectTableChanges] = useState<TableChangesProps>(defaultProjectTableChanges);
  const [enumerations, setEnumerations] =
    useState<EnumerationDto[]>([]);
  const [followUps, setFollowUps] =
    useState<FollowUpsDto>({});

  const useGetProjects = useCallback(async () => {
    const filtersDto = getProjectsTableFiltersParameter(projectTableChanges, filterMyProjects);
    const pagingDto = getProjectsTablePaginationParameter(projectTableChanges);
    return useProjectsApi().getProjects(filtersDto, pagingDto);
  }, [filterMyProjects, projectTableChanges]);

  function callGetProjectsData() {
    useGetProjects()
      .then((result) => {
        setProjectsTableData({
          data: result?.data?.data ?? [],
          totalElements: result?.data?.totalElements ?? 0,
          totalPages: result?.data?.totalPages ?? 0,
        });
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error, 'Při načítání projektů došlo k problému', false);
      });
  }

  useEffect(() => {
    setLoading(true);
    callGetProjectsData();
  }, [projectTableChanges, filterMyProjects]);

  function canSeeUsers() {
    return authContext.hasRole(['user', 'superuser', 'manager', 'sysadmin']);
  }

  useEffect(() => {
    if (canSeeUsers()) {
      useUsersApi().getUsers()
        .then((result) => {
          const usersSelectData: UserSelectType[] = [];
          result?.data?.forEach((keycloakUser) => {
            usersSelectData.push({
              label: keycloakUser.firstName + ' ' + keycloakUser.lastName,
              value: keycloakUser.keycloakId ?? '',
            });
          });
          setUsersList(usersSelectData);
        })
        .catch((error) => {
          handleErrors(error, 'Chyba při načítání uživatelů', false);
        });
    }
  }, []);

  useEffect(() => {
    useEnumerationsApi().getEnumerations()
      .then((result) => {
        setEnumerations(result.data);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání číselniků', false);
      });
    useFollowUpsApi().getFollowUps()
      .then((result) => {
        setFollowUps(result.data);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání dat pro návaznost PR KK a SRR ČR', false);
      });
  }, []);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => getProjectsColumns(usersList, canSeeUsers(), enumerations, followUps),
    [usersList, enumerations],
  );

  const handleOnCardClick = (columnFilters: { id: string, value: any }[]) => {
    const updatedColumnFilters = [];

    updatedColumnFilters.push(
      ...projectTableChanges.columnFilters.filter(
        (filter) => filter.id != 'developmentState',
      ),
    );
    updatedColumnFilters.push(...columnFilters);

    const mergedChanges = {
      ...projectTableChanges,
      columnFilters: updatedColumnFilters,
    };

    setProjectTableChanges(mergedChanges);
  };

  const downloadFile = (data: BlobPart, filename: string) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setLoading(false);
  };

  const handleExportRowsExcel = (rowData: any[], exportMode: ExportMode) => {
    setLoading(true);
    let projectIds;
    if (ExportMode.ALL_ON_PAGE === exportMode) {
      projectIds = projectsTableData.data.map((project) => project.id);
    } else {
      projectIds = rowData.map(row => row.original.id);
    }
    const projectsDataParameter = getProjectIdsParameter(projectIds, ExportMode.ALL === exportMode);
    useProjectsApi().getProjectsExcelExport(projectsDataParameter, { responseType: 'arraybuffer' })
      .then((result) => {
        const { data, headers } = result;
        const contentDisposition = headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'export_projektu.xlsx';

        downloadFile(data, filename);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  const handleExportRowsCsv = async (rowData: any[], exportMode: ExportMode) => {
    setLoading(true);
    let projectIds;
    if (ExportMode.ALL_ON_PAGE === exportMode) {
      projectIds = projectsTableData.data.map((project) => project.id);
    } else {
      projectIds = rowData.map(row => row.original.id);
    }
    const projectsDataParameter = getProjectIdsParameter(projectIds, ExportMode.ALL === exportMode);
    useProjectsApi().getProjectsCSVExport(projectsDataParameter, { responseType: 'blob' })
      .then((result) => {
        const blob = new Blob([result.data], { type: 'text/csv' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export_projektu.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  const deleteProjects = (rowData: any[]) => {
    setLoading(true);
    const projectIds = rowData.map(row => row.original.id);
    useProjectsApi().deleteProjects({ ids: projectIds })
      .then((result) => {
        callGetProjectsData();
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error, 'Při mazání vybraných projektů došlo k problému', false);
        setLoading(false);
      });
  };

  const openDeleteProjectsConfirmationModal = (rowData: any[]) => modals.openConfirmModal({
    title: 'Opravdu chcete smazat vybrané projekty?',
    centered: true,
    children: (
      <Text size="sm">
        Tuto akci nelze vrátit zpět.
      </Text>
    ),
    labels: { confirm: 'Smazat', cancel: 'Zrušit' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteProjects(rowData),
  });

  const handleDeleteProjects = (rowData: any[]) => {
    openDeleteProjectsConfirmationModal(rowData);
  };

  const clearFilters = () => {
    setProjectTableChanges(defaultProjectTableChanges);
  };

  return (
    <>
      <ProjectsOverview
        onCardClick={handleOnCardClick}
        activeFilter={
          String(
            projectTableChanges.columnFilters.find(
              (filter) => filter.id === 'developmentState',
            )?.value) || undefined
        }
        filterMyProjects={filterMyProjects}/>
      <Space h="md"/>
      <MaterialTable
        columns={columns}
        tableData={projectsTableData}
        isLoading={loading}
        handleExportRowsExcel={handleExportRowsExcel}
        handleExportRowsCsv={handleExportRowsCsv}
        deleteProjects={handleDeleteProjects}
        clearFilters={clearFilters}
        onTableChange={(event) => {
          const hasDevelopmentStateFilter = projectTableChanges.columnFilters.some(
            (filter) => filter.id === 'developmentState',
          );
          const updatedColumnFilters = [];

          if (hasDevelopmentStateFilter) {
            updatedColumnFilters.push(
              ...projectTableChanges.columnFilters.filter(
                (filter) => filter.id === 'developmentState',
              ),
            );
          }
          updatedColumnFilters.push(...event.columnFilters);

          const mergedChanges = {
            ...projectTableChanges,
            columnFilters: updatedColumnFilters,
            pagination: event.pagination,
            sorting: event.sorting,
            globalFilter: event.globalFilter,
          };

          setProjectTableChanges(mergedChanges);
        }}/>
    </>
  );
};
