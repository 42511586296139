import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './App';
import AuthContextProvider from './core/auth/AuthContextProvider';
import { MantineProvider } from '@mantine/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { mantineDpkkTheme } from './core/style/MantineDpkkTheme';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './styles.css';
import { ModalsProvider } from '@mantine/modals';

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(
  <MantineProvider theme={mantineDpkkTheme}>
    <ModalsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StrictMode>
          <AuthContextProvider>
            <RouterProvider router={router}/>
          </AuthContextProvider>
        </StrictMode>
      </LocalizationProvider>
    </ModalsProvider>
  </MantineProvider>,
);
