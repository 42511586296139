import { Switch } from '@mantine/core';
import React from 'react';

interface CustomSwitchProps {
  label?: string;
  inputProps: any;
  disabled?: boolean;
}

export function CustomSwitch({ label, inputProps, disabled }: CustomSwitchProps) {
  return (
    <Switch
      {...inputProps}
      label={label}
      disabled={disabled}
      mt={30}
      mb={30}
      radius={10}
      color={'dpkk-blue'}
    />
  );
}
