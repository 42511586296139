import format from 'date-fns/format';
import { TableChangesProps } from '../../components/MaterialTable/MaterialTable.model';
import {
  GetProjectsExcelExportProjectExportDataParameter,
  GetProjectsOverviewProjectOverviewFiltersParameter,
  GetProjectsPaginationParameter,
  GetProjectsProjectFiltersParameter,
  GetUsersPageUserFiltersParameter,
} from '../../build/generated-sources/openapi';
import dayjs from 'dayjs';

export const stringToDate = (dateString?: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }
  const [year, month, day] = dateString.split('-').map(Number);

  return new Date(year, month - 1, day);
};

export const stringDateToFormattedString = (dateString: any): string => {
  if (!dateString) {
    return '';
  }
  // @ts-ignore
  return format(stringToDate(dateString), 'dd.MM.yyyy');
};

export const dateToFormattedStringForSave = (date?: Date): string => {
  if (!date) {
    return '';
  }
  return format(date, 'yyyy-MM-dd');
};

export const stringToDateTime = (dateTimeString?: string): Date | undefined => {
  if (!dateTimeString) {
    return undefined;
  }
  const [datePart, timePart] = dateTimeString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);
  return new Date(year, month - 1, day, hour, minute, second);
};

export const stringDateTimeToFormattedString = (dateTimeString: any): string => {
  if (!dateTimeString) {
    return '';
  }
  // @ts-ignore
  return format(stringToDateTime(dateTimeString), 'dd.MM.yyyy HH:mm');
};

export const dateTimeToFormattedStringForSave = (dateTime?: Date): string => {
  if (!dateTime) {
    return '';
  }
  return format(dateTime, 'yyyy-MM-dd HH:mm');
};

export const getProjectsOverviewFiltersParameter = (overviewFilters: any[],
  filterMyProjects: boolean): GetProjectsProjectFiltersParameter => {
  return JSON.stringify({
    overviewFilters: overviewFilters,
    filterMyProjects: filterMyProjects,
  }) as GetProjectsOverviewProjectOverviewFiltersParameter;
};

export const getProjectsTableFiltersParameter = (tableChanges: TableChangesProps,
  filterMyProjects: boolean): GetProjectsProjectFiltersParameter => {
  const replacer = (key: string, value: any) => {
    if (typeof value === 'string' && !isNaN(Date.parse(value)) && dayjs(value, 'd. M. yyyy h:mm:ss').isValid()) {
      return new Date(value).toLocaleString('cs-CZ', { timeZone: 'Europe/Prague' });
    }
    return value;
  };
  return JSON.stringify({
    globalFilter: tableChanges.globalFilter,
    columnFilters: tableChanges.columnFilters,
    filterMyProjects: filterMyProjects,
  }, replacer,
  ) as GetProjectsProjectFiltersParameter;
};

export const getProjectsTablePaginationParameter = (tableChanges: TableChangesProps): GetProjectsPaginationParameter => {
  return JSON.stringify({
    sorting: tableChanges.sorting,
    pageIndex: tableChanges.pagination.pageIndex,
    pageSize: tableChanges.pagination.pageSize,
  }) as GetProjectsPaginationParameter;
};

export const getProjectIdsParameter = (projectIds: number[], exportAll: boolean): GetProjectsExcelExportProjectExportDataParameter => {
  return JSON.stringify({
    projectIds: projectIds,
    exportAll: exportAll,
  }) as GetProjectsExcelExportProjectExportDataParameter;
};

export const getUsersTableFilters = (tableChanges: TableChangesProps): GetUsersPageUserFiltersParameter => {
  return JSON.stringify({
    globalFilter: tableChanges.globalFilter,
    columnFilters: tableChanges.columnFilters,
  }) as GetUsersPageUserFiltersParameter;
};

export const getUsersTablePagination = (tableChanges: TableChangesProps): GetProjectsPaginationParameter => {
  return JSON.stringify({
    sorting: tableChanges.sorting,
    pageIndex: tableChanges.pagination.pageIndex,
    pageSize: tableChanges.pagination.pageSize,
  }) as GetProjectsPaginationParameter;
};

export const getUserNotificationsTablePagination = ():
GetProjectsPaginationParameter => {
  return JSON.stringify({
    sorting: [],
    pageIndex: 0,
    pageSize: 10,
  }) as GetProjectsPaginationParameter;
};

export const getExpensesYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  const lastFiveYears = Array.from({ length: 5 }, (_, index) => currentYear - 4 + index);
  const nextFourYears = Array.from({ length: 4 }, (_, index) => currentYear + index + 1); // Note: +1 to exclude the current year
  return [...lastFiveYears, ...nextFourYears];
};

export function isNowBetween(dateFrom?: Date, dateTo?: Date): boolean {
  const now = new Date();
  if (dateFrom && dateTo) {
    return now >= dateFrom && now <= dateTo;
  } else if (dateFrom) {
    return now >= dateFrom;
  } else if (dateTo) {
    return now <= dateTo;
  } else {
    return false;
  }
}

export function truncateString(inputString?: string, maxLength?: number): string {
  if (!inputString) {
    return '';
  }
  if (inputString.length <= (maxLength ?? 30)) {
    return inputString;
  } else {
    return inputString.substring(0, (maxLength ?? 30)) + '...';
  }
}

export function formatNumberWithCurrency(number: number): string {
  if (!number && number != 0) {
    return '';
  }
  const formattedNumber = number.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${formattedNumber} Kč`;
}
