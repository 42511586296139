import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Menu, Paper, ScrollArea, Space, Text, Title, Tooltip } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconBell, IconBellCheck, IconBellFilled } from '@tabler/icons-react';
import { CommentCard } from '../CommentsDrawer/CommentCard';
import { ProjectNotificationResponseDto } from '../../build/generated-sources/openapi';
import { useProjectNotificationsApi } from '../../service/Api.service';
import { getUserNotificationsTablePagination, stringToDateTime } from '../../core/util/Util';
import { NotificationType, showNotification } from '../../core/notifications/Notifications';
import handleErrors from '../../core/error-handler/ErrorHandler';

export function NotificationCentre() {
  const viewport = useRef<HTMLDivElement>(null);
  const [userNotifications, setUserNotifications] = useState<ProjectNotificationResponseDto[]>([]);
  const [containsNewComments, setContainsNewComments] = useState<boolean>(false);

  const scrollToTop = () => viewport.current!.scrollTo({ top: 0, behavior: 'smooth' });

  const scrollToBottom = () =>
    viewport.current!.scrollTo({ top: viewport.current!.scrollHeight, behavior: 'smooth' });

  const fetchData = () => {
    const pagingDto = getUserNotificationsTablePagination();
    useProjectNotificationsApi().getUserNotifications(pagingDto)
      .then((result) => {
        const sortedByDescData = result?.data?.data?.slice().sort(
          // @ts-ignore
          (a, b) => stringToDateTime(b.created).getTime() - stringToDateTime(a.created).getTime(),
        );
        // @ts-ignore
        setContainsNewComments(sortedByDescData.some((notification) => notification.read === false));
        // @ts-ignore
        setUserNotifications(sortedByDescData);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání uživatelských oznámení', false);
      });
  };

  const markAllRead = () => {
    useProjectNotificationsApi().markNotificationsAsRead()
      .then((result) => {
        showNotification('Všechna oznámení byla označena jako přečtená', 'notification_centre', NotificationType.INFO, false);
        fetchData();
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při označení oznámení', false);
      });
  };

  useEffect(() => {
    const fetchDataPeriodically = () => {
      fetchData();
    };

    const intervalId = setInterval(fetchDataPeriodically, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const commentCards = userNotifications.map((item, index) => {
    return (
      <div key={item.id}>
        <CommentCard notification={item} allowNavigateToProject={true} showUnread={true}></CommentCard>
        <Space h={'lg'}/>
      </div>

    );
  });

  return (
    <Box mt={24}>
      <Menu shadow="md" width={600}>
        <Menu.Target>
          {containsNewComments ? (
            <Button p={0} c={'dpkk-blue'} variant={'transparent'} leftSection={<IconBellFilled size={28}/>}></Button>
          ) : (
            <Button p={0} c={'dpkk-blue'} variant={'transparent'} leftSection={<IconBell size={28}/>}></Button>
          )}
        </Menu.Target>

        <Menu.Dropdown bg={'#f0effa'}>
          <Flex justify={'space-between'}>
            <Title order={3} c={'dpkk-blue'}>Oznámení</Title>
            {
              containsNewComments &&
                <Tooltip label="Označit vše jako přečtené" color={'dpkk-blue'} position="bottom">
                    <Button c={'dpkk-blue'} variant={'transparent'} rightSection={<IconBellCheck/>} onClick={markAllRead}/>
                </Tooltip>
            }
          </Flex>

          <Menu.Divider/>
          {commentCards.length > 0 ? (
            <>
              <ScrollArea h={400} viewportRef={viewport}>
                {commentCards}
              </ScrollArea>
              <Flex justify="space-between">
                <Button c={'dpkk-blue'} variant={'transparent'} rightSection={<IconArrowDown/>} onClick={scrollToBottom}/>
                <Button c={'dpkk-blue'} variant={'transparent'} rightSection={<IconArrowUp/>} onClick={scrollToTop}/>
              </Flex>
            </>
          ) : (
            <Paper shadow="xs" p="xl" m={30}>
              <Text>
                Momentálně pro vás neexistují žádná oznámení.
              </Text>
            </Paper>
          )}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
}
