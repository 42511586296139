import React from 'react';
import { SimpleGrid, Title } from '@mantine/core';
import { NotificationsSettingsGlobalForm } from '../../components/NotificationsSettingsForm/NotificationsSettingsGlobalForm';
import { NotificationsSettingsEmailForm } from '../../components/NotificationsSettingsForm/NotificationsSettingsEmailForm';

export const NotificationsSettings = () => {
  return (
    <>
      <Title c={'#322886'}>Nastavení oznámení</Title>
      <SimpleGrid cols={2}>
        <NotificationsSettingsGlobalForm/>
        <NotificationsSettingsEmailForm/>
      </SimpleGrid>
    </>
  );
};
