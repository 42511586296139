export const getErrorMessage = (errorName: string, params?: string[]): string => {
  switch (errorName) {
    case 'PROJECT_NOT_FOUND':
      return `Projekt ${params ? 's ID: ' + params[0] : ''} nebyl nalezen.`;
    case 'PROJECT_ACCESS_FORBIDDEN':
      return 'Nemáte správnou roli pro přístup k tomuto projektu';
    case 'INVALID_INPUT':
      return 'Váš požadavek se nepodařilo splnit';
    case 'USERS_ACCESS_FORBIDDEN':
      return 'Nemáte správnou roli pro přístup k uživatelům';
    case 'EMAIL_SEND_FAILURE':
      return 'Nepodařilo se odeslat emailovou zprávu';
    case 'EXPORT_FAILURE_EXCEPTION':
      return 'Při exportu dat došlo k chybě';
    case 'EMAIL_RECIPIENTS_MISSING':
      return 'Systém nenašel žádného uživatele, kterému by bylo možné odeslat emailovou zprávu';
    case 'PROJECT_FORM_DATA_INVALID':
      return 'Údaje zadané do projektového formuláře jsou neplatné';
    case 'ACCESS_FORBIDDEN':
      return 'Pro tuto akci nemáte správnou roli';
    default:
      return 'Váš požadavek se nepodařilo splnit';
  }
};