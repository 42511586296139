import { Group, NumberInput } from '@mantine/core';
import React from 'react';
import { CustomText } from '../../CustomText/CustomText';
import { CustomNumberInputPropsWithValue } from './CustomNumberInput.model';
import classes from '../../../core/style/PrintStyles.module.css';

export function CustomNumberInputWithValue(
  {
    label,
    name,
    required,
    disabled,
    inline,
    width,
    mr,
    rightSection,
    value,
    onChange,
  }: CustomNumberInputPropsWithValue) {

  const commonProps = {
    name,
    hideControls: true,
    withAsterisk: required,
    disabled,
    thousandSeparator: ' ',
    decimalSeparator: ',',
    rightSection: rightSection ?? 'Kč',
    mt: 10,
    radius: 10,
    w: width,
    mr: mr,
    value: value,
    decimalScale: 2,
  };

  return (inline ? (
    <Group
      justify="center"
      align="center">
      <CustomText label={label}></CustomText>
      <NumberInput
        {...commonProps}
        classNames={{
          input: classes.input,
        }}
      />
    </Group>
  ) : (
    <NumberInput
      {...commonProps}
      label={label}
      onChange={onChange}
      id={'print-input-field'}
      classNames={{
        input: classes.input,
      }}
    />
  ));
}
