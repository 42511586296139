import React, { useEffect, useState } from 'react';
import { ProjectCardFormValues } from '../ProjectCardForm.model';
import { UseFormReturnType } from '@mantine/form';
import { CustomSelect } from '../../CustomInputFields/CustomSelect/CustomSelect';
import { FollowUpsDto } from '../../../build/generated-sources/openapi';

interface FollowUpsFormFormProps {
  form: UseFormReturnType<ProjectCardFormValues>;
  followUps?: FollowUpsDto;
}

export const FollowUpsForm = ({
  form,
  followUps,
}: FollowUpsFormFormProps) => {
  const [followUpPrkkPriorityAreas, setFollowUpPrkkPriorityAreas] = useState<{ value: string, label: string }[]>([]);
  const [followUpPrkkSubregions, setFollowUpPrkkSubregions] = useState<{ value: string, label: string }[]>([]);
  const [followUpPrkkSpecificTargets, setFollowUpPrkkSpecificTargets] = useState<{ value: string, label: string }[]>([]);
  const [followUpPrkkMeasures, setFollowUpPrkkMeasures] = useState<{ value: string, label: string }[]>([]);
  const [followUpSrrcrStrategicTargets, setFollowUpSrrcrStrategicTargets] = useState<{ value: string, label: string }[]>([]);
  const [followUpSrrcrSpecificTargets, setFollowUpSrrcrSpecificTargets] = useState<{ value: string, label: string }[]>([]);
  const [followUpSrrcrTypeMeasures, setFollowUpSrrcrTypeMeasures] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    if (followUps && followUps.followUpPrkkPriorityArea) {
      setFollowUpPrkkPriorityAreas(followUps?.followUpPrkkPriorityArea
        .map(entry => {
          return ({ value: entry.id + '', label: entry.title ?? '' });
        }));
    }
    if (followUps && followUps.followUpSrrcrStrategicTarget) {
      setFollowUpSrrcrStrategicTargets(followUps?.followUpSrrcrStrategicTarget
        .map(entry => {
          return ({ value: entry.id + '', label: entry.title ?? '' });
        }));
    }
    if (followUps && followUps.followUpSrrcrTypeMeasure) {
      setFollowUpSrrcrTypeMeasures(followUps?.followUpSrrcrTypeMeasure
        .map(entry => {
          return ({ value: entry.id + '', label: entry.title ?? '' });
        }));
    }
  }, [followUps]);

  useEffect(() => {
    if (form.values.followUpPrkkPriorityAreaId && followUps?.followUpPrkkSubregion) {
      const filteredData = followUps?.followUpPrkkSubregion
        .filter(entry => entry.parentId == form.values.followUpPrkkPriorityAreaId)
        .map(entry => ({ value: entry.id + '', label: entry.title ?? '' }));

      setFollowUpPrkkSubregions(filteredData);
    }
  }, [form.values.followUpPrkkPriorityAreaId, followUps?.followUpPrkkSubregion]);

  useEffect(() => {
    if (form.values.followUpPrkkSubregionId && followUps?.followUpPrkkSpecificTarget) {
      const filteredData = followUps?.followUpPrkkSpecificTarget
        .filter(entry => entry.parentId == form.values.followUpPrkkSubregionId)
        .map(entry => ({ value: entry.id + '', label: entry.title ?? '' }));

      setFollowUpPrkkSpecificTargets(filteredData);
    }
  }, [form.values.followUpPrkkSubregionId, followUps?.followUpPrkkSpecificTarget]);

  useEffect(() => {
    if (form.values.followUpPrkkSpecificTargetId && followUps?.followUpPrkkMeasure) {
      const filteredData = followUps?.followUpPrkkMeasure
        .filter(entry => entry.parentId == form.values.followUpPrkkSpecificTargetId)
        .map(entry => ({ value: entry.id + '', label: entry.title ?? '' }));

      setFollowUpPrkkMeasures(filteredData);
    }
  }, [form.values.followUpPrkkSpecificTargetId, followUps?.followUpPrkkMeasure]);

  useEffect(() => {
    if (form.values.followUpSrrcrStrategicTargetId && followUps?.followUpSrrcrSpecificTarget) {
      const filteredData = followUps?.followUpSrrcrSpecificTarget
        .filter(entry => entry.parentId == form.values.followUpSrrcrStrategicTargetId)
        .map(entry => ({ value: entry.id + '', label: entry.title ?? '' }));

      setFollowUpSrrcrSpecificTargets(filteredData);
    }
  }, [form.values.followUpSrrcrStrategicTargetId, followUps?.followUpSrrcrSpecificTarget]);

  return (
    <>
      {followUps?.followUpPrkkPriorityArea &&
          <CustomSelect
              label="Návaznost na Program rozvoj Karlovarského kraje"
              data={followUpPrkkPriorityAreas}
              allowDeselect={!form.values.followUpPrkkSubregionId
                && !form.values.followUpPrkkSpecificTargetId
                && !form.values.followUpPrkkMeasureId}
              inputProps={form.getInputProps('followUpPrkkPriorityAreaId')}
          />
      }
      {followUps?.followUpPrkkSubregion &&
          <CustomSelect
              label=""
              disabled={!form.values.followUpPrkkPriorityAreaId}
              data={followUpPrkkSubregions}
              allowDeselect={!form.values.followUpPrkkSpecificTargetId
                && !form.values.followUpPrkkMeasureId}
              inputProps={form.getInputProps('followUpPrkkSubregionId')}
          />
      }
      {followUps?.followUpPrkkSpecificTarget &&
          <CustomSelect
              label=""
              disabled={!form.values.followUpPrkkSubregionId}
              data={followUpPrkkSpecificTargets}
              allowDeselect={!form.values.followUpPrkkMeasureId}
              inputProps={form.getInputProps('followUpPrkkSpecificTargetId')}
          />
      }
      {followUps?.followUpPrkkMeasure &&
          <CustomSelect
              label=""
              disabled={!form.values.followUpPrkkSpecificTargetId}
              data={followUpPrkkMeasures}
              allowDeselect={true}
              inputProps={form.getInputProps('followUpPrkkMeasureId')}
          />
      }
      {followUps?.followUpSrrcrStrategicTarget &&
          <CustomSelect
              label="Návaznost na Strategii regionálního rozvoje ČR"
              data={followUpSrrcrStrategicTargets}
              allowDeselect={!form.values.followUpSrrcrSpecificTargetId}
              inputProps={form.getInputProps('followUpSrrcrStrategicTargetId')}
          />
      }
      {followUps?.followUpSrrcrSpecificTarget &&
          <CustomSelect
              label=""
              disabled={!form.values.followUpSrrcrStrategicTargetId}
              data={followUpSrrcrSpecificTargets}
              allowDeselect={true}
              inputProps={form.getInputProps('followUpSrrcrSpecificTargetId')}
          />
      }
      {followUps?.followUpSrrcrTypeMeasure &&
          <CustomSelect
              label=""
              data={followUpSrrcrTypeMeasures}
              allowDeselect={true}
              inputProps={form.getInputProps('followUpSrrcrTypeMeasureId')}
          />
      }
    </>
  );
};
