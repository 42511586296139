import { AxiosError } from 'axios';
import { NotificationType, showNotification } from '../notifications/Notifications';
import { ProjectDatabaseError } from '../../build/generated-sources/openapi';
import { getErrorMessage } from './ErrorHandler.model';

export default function handleErrors(
  error: AxiosError,
  customMessage?: string,
  overwriteServerMessage?: boolean) {
  if (error.response?.data) {
    const projectDatabaseError = error.response.data as ProjectDatabaseError;
    let notificationMessage = getErrorMessage(projectDatabaseError.name ?? 'CHYBA', projectDatabaseError.params);
    if ((customMessage && overwriteServerMessage) || customMessage && !projectDatabaseError.name) {
      notificationMessage = customMessage;
    }
    showNotification(notificationMessage,
      projectDatabaseError.code + '' ?? 'axios_error',
      NotificationType.ERROR);
  } else {
    showNotification(getErrorMessage('CHYBA', []),
      'dpkk_error',
      NotificationType.ERROR);
  }
}
