import { Button } from '@mantine/core';
import React from 'react';

interface CustomButtonProps {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  variant?: string;
  leftSection?: any;
}

export function CustomButton({ label, loading, disabled, onClick, variant, leftSection }: CustomButtonProps) {
  return (
    <Button
      color={'dpkk-blue'}
      variant={variant ?? 'filled'}
      disabled={disabled}
      radius={10}
      mr={10}
      onClick={onClick}
      leftSection={leftSection}
      loading={loading}>
      {label}
    </Button>
  );
}
