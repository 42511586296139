export default function isValidIc(ic: string) {
  if (!ic) {
    return false;
  }
  // Prepend zeros for older format of identification number, which has fewer digits
  if (ic.length < 8) {
    ic = ic.padStart(8, '0');
  }
  if (ic.length !== 8) {
    return false;
  }
  // Get all digits which are used to calculate checksum digit (omit last digit)
  const digits = [...ic].slice(0, 7);
  // Calculate weighted sum
  const sum = digits.reduce((acc, val, i) => acc + Number(val) * (8 - i), 0);
  if (Number.isNaN(sum)) {
    return false;
  }
  // Calculate checksum and check if is equal to passed IC
  return ((11 - (sum % 11)) % 10).toString() === ic.charAt(7);
}

export function isValidEmail(email: string): boolean {
  return String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ) != null;
}