import { Box, Group, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { CustomSubmitButton } from '../CustomButton/CustomSubmitButton';
import {
  notificationsSettingsDefaultEmailValues,
  NotificationsSettingsEmailFormProvider,
  NotificationsSettingsEmailFormValues,
  notificationsSettingsEmailValidation,
  useNotificationsSettingsEmailForm,
} from './NotificationsSettingsForm.model';
import { CustomTextArea } from '../CustomInputFields/CustomTextArea/CustomTextArea';
import { CustomSwitch } from '../CustomInputFields/CustomSwitch/CustomSwitch';
import { CustomMultiSelect } from '../CustomInputFields/CustomMultiSelect/CustomMultiSelect';
import { useNotificationsApi, useUsersApi } from '../../service/Api.service';
import { UserSelectType } from '../../pages/ProjectsPage/Projects.model';
import { NotificationType, showNotification, updateNotification } from '../../core/notifications/Notifications';
import { CustomTextInput } from '../CustomInputFields/CustomTextInput/CustomTextInput';
import handleErrors from '../../core/error-handler/ErrorHandler';

export const NotificationsSettingsEmailForm = () => {
  const [loading, setLoading] =
    useState<boolean>(false);
  const [usersList, setUsersList] = useState<UserSelectType[]>([]);

  const form = useNotificationsSettingsEmailForm({
    name: 'notificationsSettingsEmailForm',
    validateInputOnChange: true,
    initialValues: notificationsSettingsDefaultEmailValues,
    validate: notificationsSettingsEmailValidation,
  });

  useEffect(() => {
    useUsersApi().getUsers()
      .then((result) => {
        const usersSelectData: UserSelectType[] = [];
        result?.data?.forEach((keycloakUser) => {
          usersSelectData.push({
            label: keycloakUser.firstName + ' ' + keycloakUser.lastName,
            value: keycloakUser.keycloakId ?? '',
          });
        });
        setUsersList(usersSelectData);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání uživatelů', false);
      });
  }, []);

  const onFormSubmit = (values:
  ReturnType<(values: NotificationsSettingsEmailFormValues) => NotificationsSettingsEmailFormValues>,
  event: React.FormEvent<HTMLFormElement> | undefined) => {
    setLoading(true);
    useNotificationsApi().sendEmailNotification(values)
      .then((result) => {
        showNotification('Emailová zpráva byla úspěšně uložena k odeslání', 'emailNotification', NotificationType.INFO);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  return (
    <NotificationsSettingsEmailFormProvider form={form}>
      <form onSubmit={form.onSubmit((values, event) => {
        if (onFormSubmit) {
          onFormSubmit(values, event);
        }
      })}>
        <Box mt={50} w={600}>
          <Title order={3} c={'#322886'}>Emailové oznámení</Title>
          <CustomTextInput
            label="Předmět"
            inputProps={form.getInputProps('subject')}
            required={true}
          />
          <CustomTextArea
            label="Obsah"
            inputProps={form.getInputProps('content')}
            required={true}
          />
          <CustomSwitch
            label="Odeslat všem"
            disabled={!!form.values.recipientKeycloakIds && form.values.recipientKeycloakIds?.length > 0}
            inputProps={form.getInputProps('sendToAll')}
          />
          <CustomMultiSelect
            label="Odeslat uživatelům"
            data={usersList}
            required={form.values.sendToAll === false}
            disabled={form?.values?.sendToAll == true}
            inputProps={form.getInputProps('recipientKeycloakIds')}
          />
          <Group justify="flex-end" mt="md">
            <CustomSubmitButton label={'Odeslat'} loading={loading}/>
          </Group>
        </Box>
      </form>
    </NotificationsSettingsEmailFormProvider>
  );
};
