import { ActionIcon, Tooltip } from '@mantine/core';
import React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

interface CustomActionIconProps {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: string;
  leftSection?: any;
  tooltipText?: string;
  color?: string;
  mr?: number;
  mt?: number;
  ml?: number;
  badgeCount?: number;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export function CustomActionIcon({
  disabled,
  onClick,
  variant,
  leftSection,
  tooltipText,
  color,
  mr,
  mt,
  ml,
  badgeCount,
}: CustomActionIconProps) {
  const actionIcon = (
        <Tooltip color={'dpkk-blue'} label={tooltipText}>
            <ActionIcon
                color={color}
                size="lg"
                variant={variant}
                mr={mr ?? 0}
                mt={mt ?? 0}
                ml={ml ?? 0}
                onClick={onClick}
                data-disabled={disabled}
            >
                {leftSection}
            </ActionIcon>
        </Tooltip>
  );

  return (
        <>
            {(badgeCount && badgeCount > 0) ? (
                <StyledBadge badgeContent={badgeCount} color="primary" max={999}>
                    {actionIcon}
                </StyledBadge>
            ) : (
                <>
                    {actionIcon}
                </>
            )
            }
        </>
  )
  ;
}
