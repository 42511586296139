import { notifications } from '@mantine/notifications';

export enum NotificationType {
  INFO = 'INFO',
  ERROR = 'CHYBA',
}

export function showNotification(message: string, id: string, type: NotificationType, loading: boolean = false) {
  notifications.show({
    title: type,
    message: message,
    loading: loading,
    id: id,
    autoClose: 5000,
    color: NotificationType.INFO === type ? 'green' : 'red',
  });
}

export function updateNotification(message: string, id: string, type: NotificationType) {
  notifications.update({
    title: type,
    message: message,
    loading: false,
    id: id,
    autoClose: 5000,
    color: NotificationType.INFO === type ? 'green' : 'red',
  });
}
