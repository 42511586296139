import { Progress, Text, Group, Paper, ThemeIcon } from '@mantine/core';
import classes from './OverviewCard.module.css';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface OverviewCardProps {
  title: string;
  description: string;
  outOfText: string;
  filterId?: string;
  percentage: number;
  totalFilteredItems: number;
  totalItems: number;
  icon: ReactJSXElement;
  isActiveFilter: boolean;
  onCardClick?: (filterId: any) => void;
}

export function OverviewCard({
  title,
  description,
  outOfText,
  filterId,
  percentage,
  totalFilteredItems,
  totalItems,
  icon,
  isActiveFilter,
  onCardClick,
}: OverviewCardProps) {
  const formattedPercentage = Number(percentage.toFixed(2));

  return (
    <Paper
      radius="md"
      withBorder
      bg={isActiveFilter ? 'rgb(248,248,255)' : undefined}
      className={classes.card}
      mt={20}
      onClick={() => {
        if (onCardClick) {
          onCardClick(filterId);
        }
      }}>
      <ThemeIcon className={classes.icon} size={60} radius={60} color={'#3c2f97'}>
        {icon}
      </ThemeIcon>

      <Text ta="center" fw={700} className={classes.title}>
        {title}
      </Text>
      <Text c="dimmed" ta="center" fz="sm">
        {description}
      </Text>

      <Group justify="space-between" mt="xs">
        <Text fz="sm" c="dimmed">
        </Text>
        <Text fz="sm" c="dimmed">
          {formattedPercentage}%
        </Text>
      </Group>

      <Progress.Root size={'xl'}>
        <Progress.Section value={formattedPercentage} color="#bf1e2d">
          <Progress.Label></Progress.Label>
        </Progress.Section>
      </Progress.Root>

      <Group justify="space-between" mt="md">
        <Text fz="sm">{totalFilteredItems} / {totalItems} {outOfText}</Text>
        {/*<Badge size="sm">4 days left</Badge>*/}
      </Group>
    </Paper>
  );
}
