import { createFormContext, isNotEmpty } from '@mantine/form';
import { FormValidateInput } from '@mantine/form/lib/types';
import {
  GlobalNotificationDto,
} from '../../build/generated-sources/openapi';
import { stringToDateTime } from '../../core/util/Util';

export interface NotificationsSettingsGlobalFormValues {
  active?: boolean;
  content?: string;
  displayFrom?: Date;
  displayTo?: Date;
}

export const notificationsSettingsGlobalValidation: FormValidateInput<NotificationsSettingsGlobalFormValues> = {
  content: (value, values) =>
    values.active == true && !value ? 'Obsah musí být vyplněn' : null,
};

export const notificationsSettingsDefaultGlobalValues: NotificationsSettingsGlobalFormValues = {
  active: false,
  content: '',
  displayFrom: undefined,
  displayTo: undefined,
};

export function getNotificationsSettingsGlobalValues(
  globalNotification?: GlobalNotificationDto,
): NotificationsSettingsGlobalFormValues {
  if (!globalNotification) {
    return notificationsSettingsDefaultGlobalValues;
  }
  return {
    active: globalNotification.active ?? false,
    content: globalNotification.content ?? '',
    displayFrom: stringToDateTime(globalNotification.displayFrom),
    displayTo: stringToDateTime(globalNotification.displayTo),
  };
}

export const [NotificationsSettingsGlobalFormProvider, useNotificationsSettingsGlobalFormContext,
  useNotificationsSettingsGlobalForm] =
  createFormContext<NotificationsSettingsGlobalFormValues>();

export interface NotificationsSettingsEmailFormValues {
  subject?: string;
  content?: string;
  sendToAll?: boolean;
  recipientKeycloakIds?: string[];
}

export const notificationsSettingsEmailValidation: FormValidateInput<NotificationsSettingsEmailFormValues> = {
  subject: isNotEmpty('Předmět musí být vyplněn'),
  content: isNotEmpty('Obsah musí být vyplněn'),
  recipientKeycloakIds: (value, values) =>
    values.sendToAll == false && value?.length === 0 ? 'Označte vše nebo vyberte příjemce' : null,
};

export const notificationsSettingsDefaultEmailValues: NotificationsSettingsEmailFormValues = {
  subject: '',
  content: '',
  sendToAll: false,
  recipientKeycloakIds: [],
};

export const [NotificationsSettingsEmailFormProvider, useNotificationsSettingsEmailFormContext,
  useNotificationsSettingsEmailForm] =
  createFormContext<NotificationsSettingsEmailFormValues>();
