import React, { useContext } from 'react';
import { AuthContext } from './AuthContextProvider';
import { Navigate, Outlet } from 'react-router-dom';

export function RolesAuthRoute({ roles, navigatePath }: { roles: string[], navigatePath?: string }) {
  const authContext = useContext(AuthContext);
  const canAccess = authContext.hasRole(roles);

  if (canAccess) {
    return (
      <Outlet/>
    );
  } else {
    return <Navigate to={navigatePath || '/'}/>;
  }
}