import { createFormContext, isNotEmpty, UseFormReturnType } from '@mantine/form';
import { FormValidateInput } from '@mantine/form/lib/types';
import { randomId } from '@mantine/hooks';
import {
  EnumerationDto,
  ExpensesType,
  FollowUpsDto,
  KeycloakUserDto,
  ProjectExpenseDto,
  ProjectResponseDto,
} from '../../build/generated-sources/openapi';
import { getExpensesYears, stringToDate, stringToDateTime } from '../../core/util/Util';
import isValidIc, { isValidEmail } from '../../core/util/ValidationUtil';

export type ProjectCardProps = {
  data?: ProjectResponseDto;
};

export type ProjectCardFormProps = {
  form: UseFormReturnType<ProjectCardFormValues>;
  isUpdateProject?: boolean;
  isLoading?: boolean;
  combinedExpenses: Map<number, ProjectExpenseDto[]>;
  updateCombinedExpenses: (year: number, yearDataCopy: ProjectExpenseDto[]) => void;
  updateExpensesData: (expensesTotal: ExpensesTotal) => void;
  onFormSubmit?: (values: ReturnType<(values: ProjectCardFormValues) => ProjectCardFormValues>,
    event: React.FormEvent<HTMLFormElement> | undefined) => void;
  enumerations?: EnumerationDto[];
  followUps?: FollowUpsDto;
  createdBy?: KeycloakUserDto;
  createdByLegacy?: string;
};

export type ExpensesTotal = {
  considerableExpensesTotal: number; // uznatelne
  ineligibleExpensesTotal: number; // neuznatelna
  budgetEntitlementTotal: number; // narok na rozpocet
  operatingCostsTotal: number; // provozni naklady
  sustainabilityCostsTotal: number; // naklady na udrzitelnost
  subsidyReturnTotal: number; // navratnost dotace
  scheduledDepositsReceivedTotal: number; // planovane prijate zalohy
  totalExpenses: number;
  totalBudgetEntitlement: number;
  europeanTotal: number;
  stateBudgetTotal: number;
  ownResourcesTotal: number;
  resourcesBeyondScopeTotal: number;
  fundingBreakdownTotal: number;
};

export enum DevelopmentState {
  PLANNING_PHASE = 'PLANNING_PHASE',
  READY_FOR_REALIZATION = 'READY_FOR_REALIZATION',
  REALIZATION = 'REALIZATION',
  REALIZATION_COMPLETE = 'REALIZATION_COMPLETE',
  OTHER = 'OTHER',
}

export interface ProjectCardFormValues {
  name?: string;
  registrationNumber?: string;
  eventNumber?: string;
  holder?: string;
  holderIc?: string;
  holderLegalFormEnumerationId?: string;
  guarantor?: string;
  contactPerson?: string;
  contactEmails?: { key: string, email?: string }[];
  indicators?: { key: string, id?: number, description?: string, measuringUnit?: string, value?: string }[];
  manager?: string;
  briefDescription?: string;
  partners?: string;
  relatedResolution?: string;
  approval?: Date;
  totalCosts?: number;
  followUpPrkkPriorityAreaId?: string;
  followUpPrkkSubregionId?: string;
  followUpPrkkSpecificTargetId?: string;
  followUpPrkkMeasureId?: string;
  followUpSrrcrStrategicTargetId?: string;
  followUpSrrcrSpecificTargetId?: string;
  followUpSrrcrTypeMeasureId?: string;
  actionPlanEnumerationId?: string;
  developmentStateEnumerationId?: string;
  result?: string;
  resultQuantification?: string;
  typeEnumerationId?: string;
  realizationPlace?: string;
  preprojectPreparationStart?: Date;
  implementationLength?: number;
  territorialDecisionEnumerationId?: string;
  financialEnd?: Date;
  buildingPermitEnumerationId?: string;
  sustainabilityPeriodStart?: Date;
  sustainabilityPeriodEnd?: Date;
  implementationStart?: Date;
  implementationEnd?: Date;
  initiativeEnumerationId?: string;
  totalEstimatedCosts?: number;
  preparationCosts?: number;
  implementationCosts?: number;
  fundingMainSourceEnumerationId?: string;
  notes?: string;
  keyWord?: string;
  operationalProgram?: string;
  priorityAxis?: string;
  investmentPriority?: string;
  territorialDimension?: string;
  european?: number;
  stateBudget?: number;
  ownResources?: number;
  created?: Date;
  createdBy?: string;
  updated?: Date;
  updatedBy?: string;
}

export const projectCardValidation: FormValidateInput<ProjectCardFormValues> = {
  name: isNotEmpty('Název musí být vyplněn'),
  holderIc: (value) => {
    if (!value || value.length === 0) {
      return 'IČ Nositele musí být vyplněn';
    } else if (!isValidIc(value)) {
      return 'Zadané IČO je neplatné';
    }
    return null;
  },
  holder: isNotEmpty('Název nositele projektu musí být vyplněn'),
  holderLegalFormEnumerationId: isNotEmpty('Právní forma nositele projektu musí být vyplněna'),
  guarantor: isNotEmpty('Garant musí být vyplněn'),
  contactPerson: isNotEmpty('Kontaktní osoba/tel musí být vyplněna'),
  contactEmails: {
    email: (value) => {
      if (!value || value.length === 0) {
        return 'Kontaktní email musí být vyplněn';
      } else if (!isValidEmail(value)) {
        return 'Zadaná emailová adresa je neplatná';
      }
      return null;
    },
  },
  briefDescription: isNotEmpty('Stručný popis projektu musí být vyplněn'),
  typeEnumerationId: isNotEmpty('Typ projektu musí být vyplněn'),
  realizationPlace: isNotEmpty('Místo realizace musí být vyplněno'),
  developmentStateEnumerationId: isNotEmpty('Stav rozpracovanosti navrhovaného projektu musí být vyplněn'),
  implementationStart: isNotEmpty('Zahájení realizace musí být vyplněno'),
  implementationEnd: (value, values) =>
    !value || (values.implementationStart && (value < values.implementationStart))
      ? 'Ukončení realizace musí být vyplněno a musí být po datu zahájení realizace' : null,
  totalEstimatedCosts: isNotEmpty('Celkové (předpokládané) náklady projektu musí být vyplněny'),
};

export const projectCardDefaultValues: ProjectCardFormValues = {
  name: '',
  registrationNumber: '',
  eventNumber: '',
  holder: '',
  holderIc: '',
  holderLegalFormEnumerationId: undefined,
  guarantor: '',
  contactPerson: '',
  contactEmails: [{ key: randomId() }],
  indicators: Array.from({ length: 3 }, () => ({ key: randomId() })),
  manager: '',
  briefDescription: '',
  partners: '',
  relatedResolution: '',
  approval: undefined,
  totalCosts: undefined,
  followUpPrkkPriorityAreaId: undefined,
  followUpPrkkSubregionId: undefined,
  followUpPrkkSpecificTargetId: undefined,
  followUpPrkkMeasureId: undefined,
  followUpSrrcrStrategicTargetId: undefined,
  followUpSrrcrSpecificTargetId: undefined,
  followUpSrrcrTypeMeasureId: undefined,
  actionPlanEnumerationId: undefined,
  developmentStateEnumerationId: undefined,
  result: '',
  resultQuantification: '',
  typeEnumerationId: undefined,
  realizationPlace: '',
  preprojectPreparationStart: undefined,
  implementationLength: undefined,
  territorialDecisionEnumerationId: undefined,
  financialEnd: undefined,
  buildingPermitEnumerationId: undefined,
  sustainabilityPeriodStart: undefined,
  sustainabilityPeriodEnd: undefined,
  implementationStart: undefined,
  implementationEnd: undefined,
  initiativeEnumerationId: undefined,
  totalEstimatedCosts: undefined,
  preparationCosts: undefined,
  implementationCosts: undefined,
  fundingMainSourceEnumerationId: undefined,
  notes: '',
  keyWord: '',
  operationalProgram: '',
  priorityAxis: '',
  investmentPriority: '',
  territorialDimension: '',
  european: 0,
  stateBudget: 0,
  ownResources: 0,
  updated: undefined,
  updatedBy: '',
  createdBy: '',
};

export function getProjectCardValues(project?: ProjectResponseDto): ProjectCardFormValues {
  if (!project) {
    return projectCardDefaultValues;
  }
  const filteredEmailList = project.contactEmails ? project?.contactEmails?.split(';').map(value => ({ email: value, key: randomId() })) : [{ email: '', key: randomId() }];
  return {
    name: project.name,
    registrationNumber: project.registrationNumber,
    eventNumber: project.eventNumber,
    holder: project.holder,
    holderIc: project.holderIc,
    holderLegalFormEnumerationId: project.holderLegalForm ? project.holderLegalForm.id + '' : undefined,
    guarantor: project.guarantor,
    contactPerson: project.contactPerson,
    contactEmails: filteredEmailList,
    indicators: project.indicators?.map(indicatorDto => ({
      key: randomId(),
      id: indicatorDto.id,
      description: indicatorDto.description,
      measuringUnit: indicatorDto.measuringUnit,
      value: indicatorDto.value,
    })),
    manager: project.manager,
    briefDescription: project.briefDescription,
    partners: project.partners,
    relatedResolution: project.relatedResolution,
    approval: stringToDate(project.approval),
    totalCosts: project.totalCosts,
    followUpPrkkPriorityAreaId: project.followUpPrkkPriorityArea ? project.followUpPrkkPriorityArea.id + '' : undefined,
    followUpPrkkSubregionId: project.followUpPrkkSubregion ? project.followUpPrkkSubregion.id + '' : undefined,
    followUpPrkkSpecificTargetId: project.followUpPrkkSpecificTarget ? project.followUpPrkkSpecificTarget.id + '' : undefined,
    followUpPrkkMeasureId: project.followUpPrkkMeasure ? project.followUpPrkkMeasure.id + '' : undefined,
    followUpSrrcrStrategicTargetId: project.followUpSrrcrStrategicTarget ? project.followUpSrrcrStrategicTarget.id + '' : undefined,
    followUpSrrcrSpecificTargetId: project.followUpSrrcrSpecificTarget ? project.followUpSrrcrSpecificTarget.id + '' : undefined,
    followUpSrrcrTypeMeasureId: project.followUpSrrcrTypeMeasure ? project.followUpSrrcrTypeMeasure.id + '' : undefined,
    actionPlanEnumerationId: project.actionPlan ? project.actionPlan?.id + '' : undefined,
    developmentStateEnumerationId: project.developmentState ? project.developmentState?.id + '' : undefined,
    result: project.result,
    resultQuantification: project.resultQuantification,
    typeEnumerationId: project.type ? project.type?.id + '' : undefined,
    realizationPlace: project.realizationPlace,
    preprojectPreparationStart: stringToDate(project.preprojectPreparationStart),
    implementationLength: project.implementationLength,
    territorialDecisionEnumerationId: project.territorialDecision ? project.territorialDecision?.id + '' : undefined,
    financialEnd: stringToDate(project.financialEnd),
    buildingPermitEnumerationId: project.buildingPermit ? project.buildingPermit?.id + '' : undefined,
    sustainabilityPeriodStart: stringToDate(project.sustainabilityPeriodStart),
    sustainabilityPeriodEnd: stringToDate(project.sustainabilityPeriodEnd),
    implementationStart: stringToDate(project.implementationStart) || new Date(),
    implementationEnd: stringToDate(project.implementationEnd) || new Date(),
    initiativeEnumerationId: project.initiative ? project.initiative?.id + '' : undefined,
    totalEstimatedCosts: project.totalEstimatedCosts,
    preparationCosts: project.preparationCosts,
    implementationCosts: project.implementationCosts,
    fundingMainSourceEnumerationId: project.fundingMainSource ? project.fundingMainSource?.id + '' : undefined,
    notes: project.notes,
    keyWord: project.keyWord,
    operationalProgram: project.operationalProgram,
    priorityAxis: project.priorityAxis,
    investmentPriority: project.investmentPriority,
    territorialDimension: project.territorialDimension,
    european: project.european,
    stateBudget: project.stateBudget,
    ownResources: project.ownResources,
    updated: stringToDateTime(project.updated),
    updatedBy: project.updatedBy ? (project.updatedBy?.firstName + ' ' + project.updatedBy?.lastName) : project.updatedByLegacy,
    createdBy: project.createdBy ? (project.createdBy?.firstName + ' ' + project.createdBy?.lastName) : project.createdByLegacy,
  };
}

export const [ProjectCardFormProvider, useUserFormContext, useProjectCardForm] =
  createFormContext<ProjectCardFormValues>();

function getYearDataMap(years: number[],
  considerableExpenses: ProjectExpenseDto[],
  ineligibleExpenses: ProjectExpenseDto[],
  budgetEntitlement: ProjectExpenseDto[],
  operatingCosts: ProjectExpenseDto[],
  sustainabilityCosts: ProjectExpenseDto[],
  subsidyReturn: ProjectExpenseDto[],
  scheduledDepositsReceived: ProjectExpenseDto[]) {
  const yearDataMap = new Map<number, ProjectExpenseDto[]>();

  years.forEach((year) => {
    const yearData = [
      ...considerableExpenses.filter((expense) => expense.year === year),
      ...ineligibleExpenses.filter((expense) => expense.year === year),
      ...budgetEntitlement.filter((expense) => expense.year === year),
      ...operatingCosts.filter((expense) => expense.year === year),
      ...sustainabilityCosts.filter((expense) => expense.year === year),
      ...subsidyReturn.filter((expense) => expense.year === year),
      ...scheduledDepositsReceived.filter((expense) => expense.year === year),
    ];

    yearDataMap.set(year, yearData);
  });
  return yearDataMap;
}

export function getDefaultYearlyExpensesDataMap() {
  const years: number[] = getExpensesYears();
  let considerableExpenses: ProjectExpenseDto[] = [];
  let ineligibleExpenses: ProjectExpenseDto[] = [];
  let budgetEntitlement: ProjectExpenseDto[] = [];
  let operatingCosts: ProjectExpenseDto[] = [];
  let sustainabilityCosts: ProjectExpenseDto[] = [];
  let subsidyReturn: ProjectExpenseDto[] = [];
  let scheduledDepositsReceived: ProjectExpenseDto[] = [];
  years.forEach((year) => {
    considerableExpenses.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.ConsiderableExpenses,
    });
    ineligibleExpenses.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.IneligibleExpenses,
    });
    budgetEntitlement.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.BudgetEntitlement,
    });
    operatingCosts.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.OperatingCosts,
    });
    sustainabilityCosts.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.SustainabilityCosts,
    });
    subsidyReturn.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.SubsidyReturn,
    });
    scheduledDepositsReceived.push({
      year: year,
      expense: 0,
      expenseType: ExpensesType.ScheduledDepositsReceived,
    });
  });

  return getYearDataMap(years,
    considerableExpenses,
    ineligibleExpenses,
    budgetEntitlement,
    operatingCosts,
    sustainabilityCosts,
    subsidyReturn,
    scheduledDepositsReceived);
}

export function getYearlyExpensesDataMap(project?: ProjectResponseDto) {
  const years: number[] = [...new Set(project?.expenses?.map((expense) => expense.year))];
  let considerableExpenses: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.ConsiderableExpenses == expense.expenseType) || [];
  let ineligibleExpenses: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.IneligibleExpenses == expense.expenseType) || [];
  let budgetEntitlement: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.BudgetEntitlement == expense.expenseType) || [];
  let operatingCosts: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.OperatingCosts == expense.expenseType) || [];
  let sustainabilityCosts: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.SustainabilityCosts == expense.expenseType) || [];
  let subsidyReturn: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.SubsidyReturn == expense.expenseType) || [];
  let scheduledDepositsReceived: ProjectExpenseDto[] = project?.expenses?.filter(
    (expense) => ExpensesType.ScheduledDepositsReceived == expense.expenseType) || [];
  return getYearDataMap(years.sort((a, b) => a - b),
    considerableExpenses,
    ineligibleExpenses,
    budgetEntitlement,
    operatingCosts,
    sustainabilityCosts,
    subsidyReturn,
    scheduledDepositsReceived);
}
