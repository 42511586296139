import { Group, Box, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { CustomSubmitButton } from '../CustomButton/CustomSubmitButton';
import {
  getNotificationsSettingsGlobalValues,
  NotificationsSettingsGlobalFormProvider,
  NotificationsSettingsGlobalFormValues,
  notificationsSettingsGlobalValidation,
} from './NotificationsSettingsForm.model';
import { CustomDateInput } from '../CustomInputFields/CustomDateInput/CustomDateInput';
import { CustomTextArea } from '../CustomInputFields/CustomTextArea/CustomTextArea';
import { NotificationType, showNotification, updateNotification } from '../../core/notifications/Notifications';
import { useNotificationsApi } from '../../service/Api.service';
import { GlobalNotificationDto } from '../../build/generated-sources/openapi';
import { useForm } from '@mantine/form';
import { CustomSwitch } from '../CustomInputFields/CustomSwitch/CustomSwitch';
import handleErrors from '../../core/error-handler/ErrorHandler';
import { dateTimeToFormattedStringForSave } from '../../core/util/Util';

export const NotificationsSettingsGlobalForm = () => {
  const [loading, setLoading] =
    useState<boolean>(false);
  const [globalNotification, setGlobalNotification] =
    useState<GlobalNotificationDto>({});
  const form = useForm<NotificationsSettingsGlobalFormValues>({
    name: 'notificationsSettingsGlobalForm',
    validateInputOnChange: true,
    initialValues: getNotificationsSettingsGlobalValues(globalNotification),
    validate: notificationsSettingsGlobalValidation,
  });

  const fetchData = (): Promise<GlobalNotificationDto> => {
    return useNotificationsApi().getGlobalNotification()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    fetchData().then((globalNotificationDto) => {
      setGlobalNotification(globalNotificationDto);
      let notificationsSettingsGlobalValues = getNotificationsSettingsGlobalValues(globalNotificationDto);
      form.setValues(notificationsSettingsGlobalValues);
      form.resetDirty(notificationsSettingsGlobalValues);
    })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání globálního oznámení', false);
      });
  }, []);

  const onFormSubmit = (values:
  ReturnType<(values: NotificationsSettingsGlobalFormValues) => NotificationsSettingsGlobalFormValues>,
  event: React.FormEvent<HTMLFormElement> | undefined) => {
    setLoading(true);
    useNotificationsApi().createGlobalNotification({
      active: values.active,
      content: values.content,
      displayFrom: dateTimeToFormattedStringForSave(values.displayFrom),
      displayTo: dateTimeToFormattedStringForSave(values.displayTo),
    })
      .then((result) => {
        showNotification('Globální oznámení bylo úspěšně uloženo', 'global_notification', NotificationType.INFO);
        setGlobalNotification(result.data);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  return (
    <>
      <NotificationsSettingsGlobalFormProvider form={form}>
        <form onSubmit={form.onSubmit((values, event) => {
          if (onFormSubmit) {
            onFormSubmit(values, event);
          }
        })}>
          <Box mt={50} w={600}>
            <Title order={3} c={'#322886'}>Globální oznámení</Title>
            <CustomSwitch
              label="Aktivní"
              inputProps={form.getInputProps('active', { type: 'checkbox' })}
            />
            <CustomTextArea
              label="Obsah"
              inputProps={form.getInputProps('content')}
              required={form?.values?.active == true}
              disabled={form?.values?.active == false}
            />
            <CustomDateInput
              label="Zobrazit oznámení od"
              inputProps={form.getInputProps('displayFrom')}
              withTime={true}
              clearable={true}
              disabled={form?.values?.active == false}
            />
            <CustomDateInput
              label="Zobrazit oznámení do"
              inputProps={form.getInputProps('displayTo')}
              withTime={true}
              clearable={true}
              disabled={form?.values?.active == false}
            />
            <Group justify="flex-end" mt="md">
              <CustomSubmitButton
                label={'Uložit'}
                loading={loading}/>
            </Group>
          </Box>
        </form>
      </NotificationsSettingsGlobalFormProvider>
    </>
  );
};
