import { Box, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ProjectResponseDto } from '../../build/generated-sources/openapi';
import { useProjectNotificationsApi, useProjectsApi } from '../../service/Api.service';
import { useParams } from 'react-router-dom';
import { ProjectCard } from './ProjectCard';
import handleErrors from '../../core/error-handler/ErrorHandler';

export const ProjectCardDetail = () => {
  const { id } = useParams();
  const [project, setProject] =
    useState<ProjectResponseDto>();

  useEffect(() => {
    useProjectsApi().getProjectById(Number(id))
      .then((result) => {
        setProject(result.data);
        useProjectNotificationsApi().markProjectNotificationsAsRead(result.data.id);
      })
      .catch((error) => {
        handleErrors(error);
      });
  }, []);

  return (
    (project ? (
      <>
        <Box>
          <Title c={'#322886'}>Projekt {project?.name}</Title>
        </Box>
        <ProjectCard
          data={project}/>
      </>
    ) : (
      <></>
    ))
  );
};
