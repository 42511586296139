import { Textarea } from '@mantine/core';
import React from 'react';

interface CustomTextAreaProps {
  label: string;
  inputProps: any;
  required?: boolean;
  disabled?: boolean;
  minRows?: number;
  isPrint?: boolean;
}

export function CustomTextArea({ label, inputProps, required, disabled, minRows, isPrint }: CustomTextAreaProps) {
  return (
    <Textarea
      {...inputProps}
      label={label}
      id={'print-input-field'}
      withAsterisk={required}
      disabled={disabled}
      autosize
      minRows={minRows ?? 4}
      mt={10}
      radius={10}
    />
  );
}
