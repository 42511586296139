import React from 'react';
import { ProjectCardFormValues } from '../ProjectCardForm.model';
import { Grid } from '@mantine/core';
import { CustomTextInput } from '../../CustomInputFields/CustomTextInput/CustomTextInput';
import { UseFormReturnType } from '@mantine/form';
import { CustomActionIcon } from '../../CustomActionIcon/CustomActionIcon';
import { IconPlus, IconX } from '@tabler/icons-react';
import { randomId } from '@mantine/hooks';

interface ContactEmailsFormProps {
  form: UseFormReturnType<ProjectCardFormValues>;
}

export const ContactEmailsForm = ({
  form,
}: ContactEmailsFormProps) => {
  const contactEmailFields = form?.values?.contactEmails?.map((item, index) => {
    return (
      <div key={item.key}>
        <Grid>
          <Grid.Col span={10}>
            <CustomTextInput
              label={index === 0 ? 'Kontaktní email' : ''}
              inputProps={form.getInputProps(`contactEmails.${index}.email`)}
              required={index === 0}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            {index > 0 && (
              <CustomActionIcon
                variant={'transparent'}
                color={'dpkk-blue'}
                tooltipText={'Odebrat emailovou adresu'}
                onClick={() => form.removeListItem('contactEmails', index)}
                mt={10}
                ml={-15}
                leftSection={<IconX size={28}/>}
              ></CustomActionIcon>
            )}
            {index + 1 === form.values.contactEmails?.length && (
              <CustomActionIcon
                variant={'transparent'}
                mt={index === 0 ? 34 : undefined}
                ml={index === 0 ? -15 : undefined}
                onClick={() => form.insertListItem('contactEmails', { email: '', key: randomId() })}
                leftSection={<IconPlus size={28}/>}
                tooltipText={'Přidat emailovou adresu'}
                color={'dpkk-blue'}
              ></CustomActionIcon>
            )}
          </Grid.Col>
        </Grid>
      </div>
    );
  });

  return (
    <>
      {contactEmailFields}
    </>
  );
};
