import { useState } from 'react';
import { rem, Grid } from '@mantine/core';
import {
  IconChecklist, IconFilePencil, IconFileReport,
} from '@tabler/icons-react';
import React from 'react';
import { OverviewCard } from '../OverviewCard/OverviewCard';
import { useProjectsApi } from '../../service/Api.service';
import { getProjectsOverviewFiltersParameter } from '../../core/util/Util';
import { ProjectsOverviewDto } from '../../build/generated-sources/openapi';
import { DevelopmentState } from '../ProjectCardForm/ProjectCardForm.model';
import { ProjectColumnFilter, ProjectsOverviewProps } from './ProjectsOverview.model';
import handleErrors from '../../core/error-handler/ErrorHandler';

let overviewFilters: ProjectColumnFilter[] = [
  {
    id: DevelopmentState.PLANNING_PHASE,
    title: 'Projekty ve fázi plánování a přípravy',
    icon: <IconFilePencil style={{ width: rem(32), height: rem(32) }} stroke={1.5}/>,
    percentage: 0,
    totalProjects: 0,
    totalFilteredProjects: 0,
    filter: {
      name: DevelopmentState.PLANNING_PHASE,
      columnFilters: [{
        id: 'developmentState',
        value: DevelopmentState.PLANNING_PHASE as any,
      }],
    },
  },
  {
    id: DevelopmentState.READY_FOR_REALIZATION,
    title: 'Projekty připraveny k realizaci',
    icon: <IconFileReport style={{ width: rem(32), height: rem(32) }} stroke={1.5}/>,
    percentage: 0,
    totalProjects: 0,
    totalFilteredProjects: 0,
    filter: {
      name: DevelopmentState.READY_FOR_REALIZATION,
      columnFilters: [{
        id: 'developmentState',
        value: DevelopmentState.READY_FOR_REALIZATION as any,
      }],
    },
  },
  {
    id: DevelopmentState.REALIZATION,
    title: 'Projekty v realizaci',
    icon: <IconChecklist style={{ width: rem(32), height: rem(32) }} stroke={1.5}/>,
    percentage: 0,
    totalProjects: 0,
    totalFilteredProjects: 0,
    filter: {
      name: DevelopmentState.REALIZATION,
      columnFilters: [{
        id: 'developmentState',
        value: DevelopmentState.REALIZATION as any,
      }],
    },
  },
];

export function ProjectsOverview({
  onCardClick,
  filterMyProjects,
  activeFilter,
}
: ProjectsOverviewProps) {
  const [projectsOverview, setProjectsOverview] = useState<ProjectsOverviewDto[]>([]);

  React.useEffect(() => {
    const filtersDto = getProjectsOverviewFiltersParameter(overviewFilters.map((filter) => filter.filter), filterMyProjects);
    useProjectsApi().getProjectsOverview(filtersDto)
      .then((result) => {
        setProjectsOverview(result.data);
        result.data.forEach((overview) => {
          const filterToUpdate = overviewFilters.find(
            (filter) => filter.filter.name === overview.name,
          );

          if (filterToUpdate) {
            filterToUpdate.totalProjects = overview.totalProjects || 0;
            filterToUpdate.totalFilteredProjects = overview.totalFilteredProjects || 0;

            if (overview.totalProjects && overview.totalProjects != 0) {
              // @ts-ignore
              filterToUpdate.percentage = (overview.totalFilteredProjects / overview.totalProjects) * 100;
            } else {
              filterToUpdate.percentage = 0;
            }
          }
        });
      })
      .catch((error) => {
        handleErrors(error, 'Při načítání projektových karet došlo k problému', false);
      });
  }, []);

  const overviewBoxes = overviewFilters.map((item, index) => (
    <React.Fragment key={item.filter.name}>
      <Grid.Col span={{ base: 12, md: 12, lg: 6, xl: 3 }}>
        <OverviewCard
          title={item.title}
          description={''}
          outOfText={'projektů'}
          filterId={item.id}
          percentage={item.percentage}
          totalFilteredItems={item.totalFilteredProjects}
          totalItems={item.totalProjects}
          icon={item.icon}
          isActiveFilter={activeFilter == item.filter.name}
          onCardClick={(filterId: any) => {
            if (onCardClick) {
              const clickedFilter = overviewFilters.find((filter) => filter.id === filterId)?.filter;
              if (clickedFilter) {
                onCardClick(clickedFilter.columnFilters);
              }
            }
          }}
        ></OverviewCard>
      </Grid.Col>
    </React.Fragment>
  ));

  return (
    <>
      <Grid justify="center" gutter={{ base: 1, xs: 1, md: 1, lg: 1, xl: 50 }}>
        {overviewBoxes}
      </Grid>
    </>
  );
}
