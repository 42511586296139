import { Box, Divider, Grid, Group, SimpleGrid, Switch, Text } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectCardFormProps, ProjectCardFormProvider } from './ProjectCardForm.model';
import { CustomDateInput } from '../CustomInputFields/CustomDateInput/CustomDateInput';
import { CustomTextInput } from '../CustomInputFields/CustomTextInput/CustomTextInput';
import { CustomTextArea } from '../CustomInputFields/CustomTextArea/CustomTextArea';
import { CustomSelect } from '../CustomInputFields/CustomSelect/CustomSelect';
import { FinancialPlanForm } from './ProjectCardSubForms/FinancialPlanForm';
import { CustomNumberInput } from '../CustomInputFields/CustomNumberInput/CustomNumberInput';
import classes from './ProjectCardForm.module.css';
import { CustomSubmitButton } from '../CustomButton/CustomSubmitButton';
import { EnumerationType } from '../../build/generated-sources/openapi';
import { NotificationType, showNotification } from '../../core/notifications/Notifications';
import { IndicatorsForm } from './ProjectCardSubForms/IndicatorsForm';
import { ContactEmailsForm } from './ProjectCardSubForms/ContactEmailsForm';
import { FollowUpsForm } from './ProjectCardSubForms/FollowUpsForm';
import { AuthContext } from '../../core/auth/AuthContextProvider';

export const ProjectCardForm = React.forwardRef((props: ProjectCardFormProps, ref) => {
  const {
    form,
    isUpdateProject,
    isLoading,
    combinedExpenses,
    updateCombinedExpenses,
    updateExpensesData,
    onFormSubmit,
    enumerations,
    followUps,
    createdBy,
    createdByLegacy,
  } = props;
  const [approval, setApproval] = useState<boolean>(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setApproval(!!form.values.approval);
  }, [form.values.approval]);

  const handleSwitchChange = (event: any) => {
    setApproval(event.currentTarget.checked);
    if (!event.currentTarget.checked) {
      form.setFieldValue('approval', undefined);
    }
  };

  function canEditProject() {
    // superuser, manager and sysadmin can edit ALL projects
    return authContext.hasRole(['superuser', 'manager', 'sysadmin'])
      // can create a new project
      || !isUpdateProject
      // external_user and user can only edit their own projects
      || (authContext.hasRole(['external_user', 'user']) && ((createdBy && createdBy?.keycloakId == authContext.userProfile.keycloakId) || (createdByLegacy && createdByLegacy == authContext.userProfile.lastName + ' ' + authContext.userProfile.firstName))
      );
  }

  return (
    // @ts-ignore
    <div ref={ref}>
      <ProjectCardFormProvider form={form}>
        <form onSubmit={form.onSubmit(
          (values, event) => {
            if (onFormSubmit) {
              onFormSubmit(values, event);
            }
          },
          (validationErrors, _values, _event) => {
            showNotification('Formulář je neplatný. Zkontrolujte prosím, zda máte vše správně vyplněno', 'validationErrors', NotificationType.ERROR);
          },
        )}>
          <Box className={classes.box}>
            <Grid grow>
              <Grid.Col span={6}>
                <CustomTextArea
                  label="Název projektu"
                  inputProps={form.getInputProps('name')}
                  required={true}
                  minRows={7}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomTextInput
                  label="Název nositele projektu"
                  inputProps={form.getInputProps('holder')}
                  required={true}
                />
                <CustomTextInput
                  label="IČ Nositele"
                  inputProps={form.getInputProps('holderIc')}
                  required={true}
                />
                <CustomSelect
                  label="Právní forma nositele projektu"
                  required={true}
                  data={enumerations?.filter(
                    (enumeration) => enumeration.type === EnumerationType.HolderLegalForm)
                    .map(entry => {
                      return ({ value: entry.id + '', label: entry.translation });
                    })}
                  inputProps={form.getInputProps('holderLegalFormEnumerationId')}
                />
              </Grid.Col>
              {
                isUpdateProject &&
                  <Grid.Col span={6}>
                      <CustomTextInput // automatically generated on BE
                          label="Evidenční číslo"
                          disabled={true}
                          inputProps={form.getInputProps('registrationNumber')}
                      />
                  </Grid.Col>
              }
              <Grid.Col span={6}>
                <CustomTextInput
                  label="Číslo akce"
                  inputProps={form.getInputProps('eventNumber')}
                />
              </Grid.Col>
            </Grid>

            <CustomTextInput
              label="Garant"
              inputProps={form.getInputProps('guarantor')}
              required={true}
            />
            <CustomTextInput
              label="Kontaktní osoba/tel"
              inputProps={form.getInputProps('contactPerson')}
              required={true}
            />

            <Divider mt={12}/>
            <ContactEmailsForm form={form}/>
            <Divider mt={12}/>

            <CustomTextInput
              label="Vedoucí projektu"
              inputProps={form.getInputProps('manager')}
            />
            <CustomTextArea
              label="Stručný popis projektu"
              inputProps={form.getInputProps('briefDescription')}
              required={true}
            />
            <SimpleGrid cols={2}>
              <CustomTextInput
                label="Usnesení související s projektem"
                inputProps={form.getInputProps('relatedResolution')}
              />
              <SimpleGrid cols={2} spacing={'xs'}>
                <Switch
                  label="Schválení závazného finančního příslibu"
                  checked={approval}
                  onChange={handleSwitchChange}
                  mt={42}
                  mb={30}
                  ml={30}
                  radius={10}
                  color={'dpkk-blue'}
                />
                {approval && (
                  <CustomDateInput
                    label=""
                    mt={34}
                    inputProps={form.getInputProps('approval')}
                  />
                )}
              </SimpleGrid>
            </SimpleGrid>
            <CustomNumberInput
              label="Celkové náklady dle finančního příslibu"
              inputProps={form.getInputProps('totalCosts')}
            />
            <div className={classes.pageBreak}/>
            <CustomTextInput
              label="Výsledek/ výstup projektu"
              inputProps={form.getInputProps('result')}
            />
            <CustomTextInput
              label="Kvantifikace výsledku - indikátory (indikátor, měrná jednotka, hodnota)"
              inputProps={form.getInputProps('resultQuantification')}
            />
            <CustomSelect
              label="Typ projektu"
              required={true}
              data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.ProjectType)
                .map(entry => {
                  return ({ value: entry.id + '', label: entry.translation });
                })}
              inputProps={form.getInputProps('typeEnumerationId')}
            />
            <CustomTextArea
              label="Spolupracující subjekt (IČ a název)"
              inputProps={form.getInputProps('partners')}
            />
            <CustomTextInput // can add more than 1
              label="Místo realizace (kódy míst realizace a názvy míst realizace)"
              inputProps={form.getInputProps('realizationPlace')}
              required={true}
            />

            <FollowUpsForm form={form} followUps={followUps}/>

            <CustomSelect
              label="Akční plány"
              data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.ActionPlan)
                .map(entry => {
                  return ({ value: entry.id + '', label: entry.translation });
                })}
              inputProps={form.getInputProps('actionPlanEnumerationId')}
            />
            <CustomSelect
              label="Stav rozpracovanosti navrhovaného projektu"
              data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.DevelopmentState)
                .map(entry => {
                  return ({ value: entry.id + '', label: entry.translation });
                })}
              inputProps={form.getInputProps('developmentStateEnumerationId')}
              required={true}
            />
            <div className={classes.pageBreak}/>
            <Divider mt={12}/>
            <IndicatorsForm form={form}/>
            <Divider mt={12}/>
            <CustomSelect
              label="OP nebo iniciativa"
              data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Initiative)
                .map(entry => {
                  return ({ value: entry.id + '', label: entry.translation });
                })}
              inputProps={form.getInputProps('initiativeEnumerationId')}
            />
            <SimpleGrid cols={2}>
              <CustomDateInput
                label="Zahájení předprojektové přípravy"
                inputProps={form.getInputProps('preprojectPreparationStart')}
              />
            </SimpleGrid>
            <SimpleGrid cols={2}>
              <CustomDateInput
                label="Zahájení realizace"
                inputProps={form.getInputProps('implementationStart')}
                required={true}
                minDate={new Date()}
              />
              <CustomDateInput
                label="Ukončení realizace"
                inputProps={form.getInputProps('implementationEnd')}
                required={true}
                minDate={form.values.implementationStart || new Date()}
              />
            </SimpleGrid>
            <CustomNumberInput
              label="Délka fyzické realizace - v měsících"
              inputProps={form.getInputProps('implementationLength')}
              rightSection={''}
            />
            <SimpleGrid cols={2}>
              <CustomDateInput
                label="Finanční ukončení projektu"
                inputProps={form.getInputProps('financialEnd')}
              />
            </SimpleGrid>
            <SimpleGrid cols={2}>
              <CustomDateInput
                label="Zahájení doby udržitelnosti"
                inputProps={form.getInputProps('sustainabilityPeriodStart')}
              />
              <CustomDateInput
                label="Ukončení doby udržitelnosti"
                inputProps={form.getInputProps('sustainabilityPeriodEnd')}
              />
            </SimpleGrid>
            <SimpleGrid cols={2}>
              <CustomSelect
                label="Územní rozhodnutí"
                data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Decision)
                  .map(entry => {
                    return ({ value: entry.id + '', label: entry.translation });
                  })}
                inputProps={form.getInputProps('territorialDecisionEnumerationId')}
              />
              <CustomSelect
                label="Stavební povolení"
                data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Decision)
                  .map(entry => {
                    return ({ value: entry.id + '', label: entry.translation });
                  })}
                inputProps={form.getInputProps('buildingPermitEnumerationId')}
              />
            </SimpleGrid>
            <CustomNumberInput
              label="Celkové (předpokládané) náklady projektu (Kč, vč. DPH)"
              inputProps={form.getInputProps('totalEstimatedCosts')}
              required={true}
            />
            <CustomNumberInput
              label="Náklady na přípravu projektu (Kč, vč. DPH)"
              inputProps={form.getInputProps('preparationCosts')}
            />
            <CustomNumberInput
              label="Náklady na realizaci projektu (Kč, vč. DPH)"
              inputProps={form.getInputProps('implementationCosts')}
            />
            <CustomSelect
              label="Předpokládaný hlavní zdroj financování"
              data={enumerations?.filter((enumeration) => enumeration.type === EnumerationType.FundingMainSource)
                .map(entry => {
                  return ({ value: entry.id + '', label: entry.translation });
                })}
              inputProps={form.getInputProps('fundingMainSourceEnumerationId')}
            />
            <div className={classes.pageBreak}/>
            <FinancialPlanForm
              form={form}
              combinedExpenses={combinedExpenses}
              updateCombinedExpenses={updateCombinedExpenses}
              updateExpensesData={updateExpensesData}
            />
            <CustomTextInput
              label="Poznámky"
              inputProps={form.getInputProps('notes')}
            />
            <CustomTextInput
              label="Klíčové slovo"
              inputProps={form.getInputProps('keyWord')}
            />
            {
              isUpdateProject &&
                <>
                    <CustomDateInput
                        label="Datum poslední změny"
                        disabled={true}
                        withTime={true}
                        inputProps={form.getInputProps('updated')}
                    />
                    <Group>
                        <CustomTextInput
                            label="Upravil nové / původní"
                            disabled={true}
                            inputProps={form.getInputProps('updatedBy')}
                        />
                        <Text mt={40}>/</Text>
                        <CustomTextInput
                            label={''}
                            disabled={true}
                            mt={40}
                            inputProps={form.getInputProps('createdBy')}
                        />
                    </Group>
                </>
            }
            <div className={classes.printHidden}>
              {
                canEditProject() && <Group justify="flex-end" mt="md">
                      <CustomSubmitButton label={'Odeslat'} loading={isLoading}/>
                  </Group>
              }
            </div>
          </Box>
        </form>
      </ProjectCardFormProvider>
    </div>
  );
});
