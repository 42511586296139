import React from 'react';
import { Text } from '@mantine/core';

interface CustomTextProps {
  label?: string;
  mt?: number;
}

export function CustomText({ label, mt }: CustomTextProps) {
  return (label ? (
    <Text
      mt={mt ?? 10}
    >
      {label}
    </Text>) : (<></>));
}
