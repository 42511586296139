import {
  createTheme, darken,
  defaultVariantColorsResolver,
  Drawer,
  Loader,
  parseThemeColor,
  rem,
  rgba,
  VariantColorsResolver,
} from '@mantine/core';
import { CssLoader } from '../../components/CssLoader/CssLoader';

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color ?? input.theme.primaryColor,
    theme: input.theme,
  });

  if (input.variant === 'light') {
    return {
      background: rgba(parsedColor.value, 0.1),
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(1)} solid ${parsedColor.value}`,
      color: darken(parsedColor.value, 0.1),
    };
  }

  // Add new variants support
  if (input.variant === 'danger') {
    return {
      background: 'var(--mantine-color-red-9)',
      hover: 'var(--mantine-color-red-8)',
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  return defaultResolvedColors;
};

export const mantineDpkkTheme = createTheme({
  cursorType: 'pointer',
  colors: {
    'dpkk-blue': ['#f0effa', '#dddbef', '#b8b3e1', '#9289d4', '#7265c8', '#5e4fc2', '#5344c0', '#4435a9', '#3c2f97', '#322886'],
    'dpkk-red': ['#ffeaee', '#fcd5d8', '#f2a9b0', '#e97a84', '#e25360', '#de3a48', '#dd2d3c', '#c41f2e', '#b01728', '#9a0b20'],
  },
  variantColorResolver: variantColorResolver,
  fontFamily: 'Raleway Regular, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  components: {
    Drawer: Drawer.extend({
      styles: {
        header: { backgroundColor: '#f0effa' },
        content: { backgroundColor: '#f0effa' },
        title: { color: '#322886', fontWeight: 'bold' },
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, custom: CssLoader },
        type: 'custom',
        color: '#9a0b20',
      },
    }),
  },
});
