import { Box, Button, Drawer, Flex, Space } from '@mantine/core';
import { CommentCard } from './CommentCard';
import React, { useEffect, useState } from 'react';
import { useProjectNotificationsApi } from '../../service/Api.service';
import { ProjectNotificationResponseDto } from '../../build/generated-sources/openapi';
import { CommentCardCreate, CommentCardFormValues } from './CommentCardCreate';
import { stringToDateTime } from '../../core/util/Util';
import handleErrors from '../../core/error-handler/ErrorHandler';

export type CommentsDrawerProps = {
  opened: boolean;
  close: () => void;
  projectId: number;
};

export function CommentsDrawer({
  opened,
  close,
  projectId,
}: CommentsDrawerProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [createComment, setCreateComment] = useState<boolean>(false);
  const [projectNotifications, setProjectNotifications] = useState<ProjectNotificationResponseDto[]>([]);

  const fetchData = () => {
    useProjectNotificationsApi().getProjectNotifications(projectId)
      .then((result) => {
        const sortedByDescData = result.data.slice().sort(
          // @ts-ignore
          (a, b) => stringToDateTime(b.created).getTime()
            // @ts-ignore
            - stringToDateTime(a.created).getTime(),
        );
        setProjectNotifications(sortedByDescData);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání uživatelských notifikací', false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [projectId]);

  const commentCards = projectNotifications.map((item, index) => {
    return (
      <div key={item.id}>
        <CommentCard notification={item} allowNavigateToProject={false} showUnread={false}></CommentCard>
        <Space h={'lg'}/>
      </div>
    );
  });

  const onFormSubmit = (values: ReturnType<(values: CommentCardFormValues) => CommentCardFormValues>,
    event: React.FormEvent<HTMLFormElement> | undefined) => {
    setLoading(true);
    useProjectNotificationsApi().createProjectNotification(projectId, values)
      .then((result) => {
        fetchData();
        setLoading(false);
        setCreateComment(false);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při ukládání uživatelské notifikace', false);
      });
  };

  return (
    <>
      <Box>
        <Drawer
          opened={opened}
          onClose={close}
          title="Komentáře"
          position={'right'}
          size={'lg'}
          keepMounted={true}
          overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}>
          <Flex justify={'flex-end'} mb={30}>
            <Button color={'dpkk-blue'} onClick={() => setCreateComment(!createComment)}>
              {createComment ? 'Zrušit nový komentář' : 'Vytvořit komentář'}
            </Button>
          </Flex>
          {
            createComment &&
              <CommentCardCreate
                  isLoading={loading}
                  onFormSubmit={onFormSubmit}
              />
          }
          {commentCards}
        </Drawer>
      </Box>
    </>
  );
}
