import { Box, Button, Card, Flex, Group, Text } from '@mantine/core';
import { ProjectNotificationResponseDto } from '../../build/generated-sources/openapi';
import { stringDateTimeToFormattedString } from '../../core/util/Util';
import React from 'react';
import { IconPointFilled } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

export type CommentCardProps = {
  allowNavigateToProject?: boolean;
  showUnread?: boolean;
  notification?: ProjectNotificationResponseDto;
};

export function CommentCard({ notification, showUnread, allowNavigateToProject }: CommentCardProps) {

  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder mr={30} ml={30}>
        <Card.Section m={0}>
          <Group justify="flex-start" ml={10} mt={-10}>
            {allowNavigateToProject ? (
              <Card.Section component={Link} to={'/projekty/' + notification?.projectId}>
                <Text fw={'bold'} c={'dpkk-blue'}>{notification?.title}</Text>
              </Card.Section>
            ) : (
              <Card.Section>
                <Text fw={'bold'} c={'dpkk-blue'}>{notification?.title}</Text>
              </Card.Section>
            )}
            {
              !notification?.read && showUnread &&
                <Button p={0} c={'dpkk-red'} variant={'transparent'} leftSection={<IconPointFilled size={28}/>}></Button>
            }
          </Group>
        </Card.Section>
        <Card.Section m={10}>
          <Text>{notification?.text}</Text>
        </Card.Section>
        <Card.Section m={10}>
          <Group justify="space-between" ml={10} mt={10}>
            <Box ml={-20}>
              <Flex>
                <Text size="sm" c="dimmed">Autor:</Text>
              </Flex>
              <Flex>
                <Text size="sm">{notification?.author?.firstName} {notification?.author?.lastName}</Text>
              </Flex>
            </Box>
            <div>
              <Flex justify={'flex-end'}>
                <Text size="sm" c="dimmed">Datum a čas:</Text>
              </Flex>
              <Flex justify={'flex-end'}>
                <Text size="sm">{stringDateTimeToFormattedString(notification?.created)}</Text>
              </Flex>
            </div>
          </Group>
        </Card.Section>
      </Card>
    </>
  );
}
