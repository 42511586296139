import React from 'react';
import { CustomActionIcon } from '../CustomActionIcon/CustomActionIcon';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export enum ExportMode {
  ALL,
  ALL_ON_PAGE,
  SELECTED,
}

interface ExportMenuProps {
  label?: string;
  leftSection?: any;
  actions: {
    name: string,
    icon: any,
    action: () => void,
    disabled?: boolean,
    count?: number
  }[];
}

export function ExportMenu({ label, leftSection, actions }: ExportMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
      <div>
          <CustomActionIcon
              variant="transparent"
              mt={10}
              mr={10}
              color={'#3c2f97'}
              leftSection={leftSection}
              tooltipText={label}
              onClick={handleClick}
          ></CustomActionIcon>
          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
          >
              {actions.map((option, index) => (
                  <MenuItem
                      key={option.name}
                      disabled={option.disabled}
                      onClick={() => {
                        if (option.action) {
                          option.action();
                        }
                      }}
                  >
                       <Badge badgeContent={option.count || 0} color="primary" max={999} showZero>
                           <Typography mr={2}>
                               {option.name}
                           </Typography>

                  </Badge>
                  </MenuItem>
              ))}
          </Menu>
      </div>
  );
}
