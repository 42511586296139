import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { NotificationType, showNotification } from '../notifications/Notifications';

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
};

const keycloak = new Keycloak('/keycloak.json');

interface AuthContextValues {
  isAuthenticated: boolean;
  logout: () => void;
  userProfile: UserProfile;
  hasRole: (role: string[]) => boolean;
}

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  logout: () => {
  },
  userProfile: {},
  hasRole: () => false,
};

export type UserProfile = {
  firstName?: string,
  lastName?: string,
  email?: string,
  keycloakId?: string;
};

export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues,
);

interface AuthContextProviderProps {
  children: JSX.Element;
}

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>({});

  useEffect(() => {
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions,
        );

        if (!isAuthenticatedResponse) {
          keycloak.login();
        }
        setAuthenticated(isAuthenticatedResponse);
        axios.interceptors.request.use((config) => {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
          return config;
        });

        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          async (error) => {
            if (error.response === undefined) {
              throw error;
            }
            if (error.response.status === 401) {
              try {
                const result = await keycloak.updateToken(5);
                if (result) {
                  return await axios({ ...error.config });
                } else {
                  throw new Error('Unauthorized');
                }
              } catch (e) {
                keycloak.logout();
                throw e;
              }
            }
            throw error;
          },
        );
      } catch (error) {
        setAuthenticated(false);
        //showNotification('Přihlášení se nepodařilo', 'login-event', NotificationType.ERROR);
      }
    }

    initializeKeycloak();
  }, []);

  useEffect(() => {
    async function loadProfile() {
      try {
        const profile = await keycloak.loadUserProfile();
        setUserProfile({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          keycloakId: profile.id,
        });
      } catch {
        throw new Error('Unable to get user profile');
      }
    }

    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  const logout = () => {
    keycloak.logout();
  };

  /**
   * Check if the user has the given role
   * @param roles to be checked
   * @returns whether or not if the user has the role
   */
  const hasRole = (roles: string[]) => {
    return roles.some(role => keycloak.hasResourceRole(role));
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userProfile, logout, hasRole }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
