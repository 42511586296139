import { Text, Modal, Flex, Card, Space, Accordion } from '@mantine/core';
import React from 'react';
import classes from './SupportModal.module.css';
import { useDisclosure } from '@mantine/hooks';
import { CustomButton } from '../CustomButton/CustomButton';
import packageJson from '../../../package.json';

export function SupportModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const contentSupportEmail = 'support@dpkk.com';
  const technicalSupportEmail = 'support@dpkk.com';
  const subject = 'Databáze projektů - dotaz';

  const mailtoLink = `mailto:${contentSupportEmail}?subject=${encodeURIComponent(subject)}}`;

  return (
    <Flex justify={'center'}>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        classNames={{
          body: classes.modalBody,
        }}
        radius={10}
        size={'55%'}>
        <Text mb={20} fz="md" fw={600} c={'dpkk-blue'}>
          Podpora
        </Text>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Text m={10} fz="md" fw={500} c={'dpkk-blue'}>
              Obsahová podpora
            </Text>
          </Card.Section>

          <Text size="sm" c="dimmed">
            Proňková Veronika
          </Text>
          <Text size="sm" c="dimmed">
            tel.: +420 000 000 000
          </Text>
          <Text size="sm" c="dimmed">
            <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
              {contentSupportEmail}
            </a>
          </Text>
        </Card>

        <Space h={'md'}></Space>

        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Text m={10} fz="md" fw={500} c={'dpkk-blue'}>
              Technická podpora
            </Text>
          </Card.Section>

          <Text size="sm" c="dimmed">
            Kompar Marina
          </Text>
          <Text size="sm" c="dimmed">
            tel.: +420 000 000 000
          </Text>
          <Text size="sm" c="dimmed">
            <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
              {technicalSupportEmail}
            </a>
          </Text>
        </Card>

        <Space h={'md'}></Space>

        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Text m={10} fz="md" fw={500} c={'dpkk-blue'}>
              Nejčastěji kladené otázky
            </Text>
          </Card.Section>

          <Accordion>
            <Accordion.Item value={'external-user'}>
              <Accordion.Control>
                <Text size="sm" c="dimmer">
                  Proč vidim pouze svoje projekty?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text size="sm" c="dimmed">
                  Pokud jste externí uživatel, na stránkách Databáze projektů uvidite pouze vlastní projekty.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={'change-personal-info'}>
              <Accordion.Control>
                <Text size="sm" c="dimmer">
                  Chci změnit své osobní údaje
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Text size="sm" c="dimmed">
                  Osobní údaje lze změnit pouze v případě, že v nich došlo k právním změnám.
                  Chcete-li změnit své osobní údaje, kontaktujte obsahovou podporu.
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Card>
        <Flex justify={'flex-end'}>
          <Text size="sm" c="dimmed" mt={20}>
            Verze aplikace: {packageJson.version}
          </Text>
        </Flex>
      </Modal>
      <CustomButton
        label={'Podpora'}
        variant={'transparent'}
        onClick={open}
      />
    </Flex>
  );
}
