import { KeycloakUserDto } from '../../build/generated-sources/openapi';
import { translateRole } from '../../core/util/TranslateUtil';

export type RoleSelectType = {
  label: string;
  value: string;
};

export const getUsersColumns = (rolesList: RoleSelectType[]): any => {
  return [
    {
      accessorKey: 'firstName',
      enableClickToCopy: true,
      header: 'Jméno',
    },
    {
      accessorKey: 'lastName',
      enableClickToCopy: true,
      header: 'Příjmení',
    },
    {
      accessorKey: 'email',
      enableClickToCopy: true,
      header: 'Email',
    },
    {
      accessorKey: 'role',
      accessorFn: (row: KeycloakUserDto) => {
        const role = row.role;
        if (role) {
          return `${translateRole(role)}`;
        }
        return '';
      },
      filterSelectOptions: rolesList,
      filterVariant: 'select',
      enableClickToCopy: true,
      header: 'Role',
      enableSorting: false,
    },
  ];
};
