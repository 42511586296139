import { Group } from '@mantine/core';
import { FormValidateInput } from '@mantine/form/lib/types';
import { createFormContext, isNotEmpty } from '@mantine/form';
import { CustomTextArea } from '../CustomInputFields/CustomTextArea/CustomTextArea';
import { CustomTextInput } from '../CustomInputFields/CustomTextInput/CustomTextInput';
import { CustomSubmitButton } from '../CustomButton/CustomSubmitButton';
import React from 'react';
import { Card } from '@mantine/core';

export type CommentCardProps = {
  isLoading?: boolean;
  onFormSubmit?: (values: ReturnType<(values: CommentCardFormValues) => CommentCardFormValues>,
    event: React.FormEvent<HTMLFormElement> | undefined) => void;
};

export interface CommentCardFormValues {
  title?: string;
  text?: string;
}

export const commentCardValidation: FormValidateInput<CommentCardFormValues> = {
  text: isNotEmpty('Text musí být vyplněn'),
};

export const commentCardDefaultValues: CommentCardFormValues = {
  title: '',
  text: '',
};

export const [CommentCardFormProvider, useUserFormContext, useCommentCardForm] =
  createFormContext<CommentCardFormValues>();

export function CommentCardCreate({ isLoading, onFormSubmit }: CommentCardProps) {

  const form = useCommentCardForm({
    name: 'commentCardForm',
    validateInputOnChange: true,
    initialValues: commentCardDefaultValues,
    validate: commentCardValidation,
  });

  return (
    <CommentCardFormProvider form={form}>
      <form onSubmit={form.onSubmit((values, event) => {
        if (onFormSubmit) {
          onFormSubmit(values, event);
        }
      })}>
        <Card shadow="sm" padding="lg" radius="md" withBorder mt={10} mr={30} ml={30} mb={30}>
          <Card.Section m={10}>
            <CustomTextInput
              label="Název"
              inputProps={form.getInputProps('title')}
            />
          </Card.Section>
          <Card.Section m={10}>
            <CustomTextArea
              label="Text"
              inputProps={form.getInputProps('text')}
              required={true}
              minRows={7}
            />
          </Card.Section>
          <Card.Section m={10}>
            <Group justify="flex-end" mt="md">
              <CustomSubmitButton label={'Odeslat'} loading={isLoading}/>
            </Group>
          </Card.Section>
        </Card>
      </form>
    </CommentCardFormProvider>
  );
}
