import { Button, Text, Group, Modal } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNotificationsApi } from '../../service/Api.service';
import { isNowBetween, stringToDateTime } from '../../core/util/Util';
import { useDisclosure } from '@mantine/hooks';
import classes from './NotificationsSettings.module.css';
import handleErrors from '../../core/error-handler/ErrorHandler';

export function GlobalNotificationCard() {
  const [opened, { open, close }] = useDisclosure(false);
  const [content, setContent] =
    useState<string>('');

  function shouldDisplayMessage() {
    const lastDismissalTimestamp = localStorage.getItem('gnLastDismissalTimestamp');
    if (!lastDismissalTimestamp) {
      return true;
    }
    const lastDismissalTimestampAsNumber = Number(lastDismissalTimestamp);
    const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000; // 24 hours
    return lastDismissalTimestampAsNumber < twentyFourHoursAgo;
  }

  function dismissMessage() {
    close();
    localStorage.setItem('gnLastDismissalTimestamp', Date.now().toString());
  }

  useEffect(() => {
    useNotificationsApi().getGlobalNotification()
      .then((result) => {
        setContent(result.data.content || '');
        if (result.data.active
          && isNowBetween(stringToDateTime(result.data.displayFrom), stringToDateTime(result.data.displayTo))
          && shouldDisplayMessage()) {
          open();
        }
      })
      .catch((error) => {
        handleErrors(error, 'Nepodařilo se načíst globální oznámení', false);
      });
  }, []);

  return (
    <>
      {content && content != '' ? (
        <Modal
          opened={opened}
          onClose={close}
          withCloseButton={false}
          closeOnEscape={false}
          closeOnClickOutside={false}
          classNames={{
            body: classes.modalBody,
          }}
          radius={10}
          miw={500}
          maw={500}>
          <Group justify="space-between" mb="xs">
            <Text fz="md" fw={500} c={'dpkk-blue'}>
              Oznámení
            </Text>
          </Group>
          <Text c="dimmed" fz="xs">
            {content}
          </Text>
          <Group justify="flex-end" mt="md">
            <Button color={'dpkk-blue'} variant="outline" size="xs" onClick={dismissMessage}>
              Zavřít
            </Button>
          </Group>
        </Modal>
      ) : undefined}
    </>
  );
}
