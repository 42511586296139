import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUsersApi } from '../../service/Api.service';
import { MRT_ColumnDef } from 'material-react-table';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { TableChangesProps, TableData } from '../../components/MaterialTable/MaterialTable.model';
import {
  getUsersTableFilters,
  getUsersTablePagination,
} from '../../core/util/Util';
import { getUsersColumns } from './Users.model';
import UserRole from '../../core/auth/UserRole';
import { translateRole } from '../../core/util/TranslateUtil';
import handleErrors from '../../core/error-handler/ErrorHandler';

export const UsersPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [usersTableData, setUsersTableData] = useState<TableData>({ data: [], totalPages: 1, totalElements: 0 });
  const [userTableChanges, setUserTableChanges] = useState<TableChangesProps>({
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
    columnFilters: [],
    globalFilter: '',
  });

  const useGetUsers = useCallback(async () => {
    const filtersDto = getUsersTableFilters(userTableChanges);
    const pagingDto = getUsersTablePagination(userTableChanges);
    return useUsersApi().getUsersPage(filtersDto, pagingDto);
  }, [userTableChanges]);

  useEffect(() => {
    useGetUsers()
      .then((result) => {
        setUsersTableData({
          data: result?.data?.data || [],
          totalElements: result?.data?.totalElements || 0,
          totalPages: result?.data?.totalPages || 0,
        });
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání uživatelů', false);
        setLoading(false);
      });
  }, [userTableChanges]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => getUsersColumns(Object.values(UserRole).map((role) => ({
      label: translateRole(role),
      value: role,
    }))),
    [],
  );

  return (
    <MaterialTable
      columns={columns}
      tableData={usersTableData}
      isLoading={loading}
      onTableChange={(event) => {
        setUserTableChanges(event);
      }}/>
  );
};
