import { ProjectCardForm } from '../../components/ProjectCardForm/ProjectCardForm';
import {
  ExpensesTotal,
  getDefaultYearlyExpensesDataMap,
  getProjectCardValues,
  getYearlyExpensesDataMap,
  ProjectCardFormValues,
  ProjectCardProps,
  projectCardValidation,
  useProjectCardForm,
} from '../../components/ProjectCardForm/ProjectCardForm.model';
import { useEffect, useRef, useState } from 'react';
import { EnumerationDto, FollowUpsDto, ProjectExpenseDto, ProjectRequestDto } from '../../build/generated-sources/openapi';
import { useEnumerationsApi, useFollowUpsApi, useProjectsApi } from '../../service/Api.service';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationType, showNotification, updateNotification } from '../../core/notifications/Notifications';
import { Flex } from '@mantine/core';
import { useReactToPrint } from 'react-to-print';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { useDisclosure } from '@mantine/hooks';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import handleErrors from '../../core/error-handler/ErrorHandler';
import { dateToFormattedStringForSave } from '../../core/util/Util';

export const ProjectCard = ({
  data,
}: ProjectCardProps) => {
  const { id } = useParams();
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] =
    useState<boolean>(false);
  const [enumerations, setEnumerations] =
    useState<EnumerationDto[]>([]);
  const [followUps, setFollowUps] =
    useState<FollowUpsDto>({});
  const [combinedExpenses, setCombinedExpenses] =
    useState<Map<number, ProjectExpenseDto[]>>(new Map<number, ProjectExpenseDto[]>());
  const [expensesTotal, setExpensesTotal] =
    useState<ExpensesTotal>();
  const navigate = useNavigate();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.name,
    onBeforeGetContent: () => {
      document.body.style.filter = 'blur(2px)';
    },
    onAfterPrint: () => {
      document.body.style.filter = 'none';
    },
  });

  useEffect(() => {
    if (id) {
      const yearlyExpensesDataMap = getYearlyExpensesDataMap(data);
      setCombinedExpenses(yearlyExpensesDataMap);
    } else {
      const yearlyExpensesDataMap = getDefaultYearlyExpensesDataMap();
      setCombinedExpenses(yearlyExpensesDataMap);
    }
  }, [data]);

  useEffect(() => {
    useEnumerationsApi().getEnumerations()
      .then((result) => {
        setEnumerations(result.data);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání číselniků', false);
      });
    useFollowUpsApi().getFollowUps()
      .then((result) => {
        setFollowUps(result.data);
      })
      .catch((error) => {
        handleErrors(error, 'Chyba při načítání dat pro návaznost PR KK a SRR ČR', false);
      });
  }, []);

  const form = useProjectCardForm({
    name: 'projectCardDetailForm',
    validateInputOnChange: true,
    initialValues: getProjectCardValues(data),
    validate: projectCardValidation,
  });

  const createProject = (projectDto: ProjectRequestDto) => {
    useProjectsApi().createProject(projectDto)
      .then((result) => {
        showNotification('Projekt byl úspěšně uložen', 'project_' + projectDto.name, NotificationType.INFO);
        navigate('/projekty/' + result.data.id);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  const updateProject = (projectDto: ProjectRequestDto) => {
    useProjectsApi().updateProject(Number(id), projectDto)
      .then((result) => {
        showNotification('Projekt byl úspěšně uložen', 'project_' + projectDto.name, NotificationType.INFO);
        setLoading(false);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  const onFormSubmit = (values: ReturnType<(values: ProjectCardFormValues) => ProjectCardFormValues>,
    event: React.FormEvent<HTMLFormElement> | undefined) => {
    setLoading(true);
    const {
      created,
      createdBy,
      updated,
      updatedBy,
      ...projectDto
    } = values as any;
    projectDto.expenses = Array.from(combinedExpenses.values()).flatMap((entry) => entry);
    projectDto.contactEmails = values.contactEmails?.map(item => item.email).join(';');
    projectDto.indicators = values.indicators?.map(({ key, ...rest }) => rest);
    projectDto.approval = dateToFormattedStringForSave(values.approval);
    projectDto.preprojectPreparationStart = dateToFormattedStringForSave(values.preprojectPreparationStart);
    projectDto.implementationStart = dateToFormattedStringForSave(values.implementationStart);
    projectDto.implementationEnd = dateToFormattedStringForSave(values.implementationEnd);
    projectDto.financialEnd = dateToFormattedStringForSave(values.financialEnd);
    projectDto.sustainabilityPeriodStart = dateToFormattedStringForSave(values.sustainabilityPeriodStart);
    projectDto.sustainabilityPeriodEnd = dateToFormattedStringForSave(values.sustainabilityPeriodEnd);
    if (id) {
      updateProject(projectDto);
    } else {
      createProject(projectDto);
    }
  };

  const updateExpensesData = (updatedData: ExpensesTotal) => {
    setExpensesTotal(updatedData);
  };

  const updateCombinedExpenses = (year: number, yearDataCopy: ProjectExpenseDto[]) => {
    setCombinedExpenses((prevCombinedExpenses) => {
      const newCombinedExpenses = new Map(prevCombinedExpenses);
      newCombinedExpenses.set(year, yearDataCopy);
      return newCombinedExpenses;
    });
  };

  return (
    <>
      {id &&
          <>
              <Flex justify="flex-end">
                  <CustomButton onClick={handlePrint} label={'Vytisknout'}/>
                  <CustomButton onClick={open} label={'Komentáře'}/>
              </Flex>
              <CommentsDrawer
                  opened={opened}
                  close={close}
                  projectId={Number(id)}
              ></CommentsDrawer>
          </>
      }

      <ProjectCardForm
        ref={componentRef}
        form={form}
        isUpdateProject={!!data}
        isLoading={loading}
        combinedExpenses={combinedExpenses}
        updateCombinedExpenses={updateCombinedExpenses}
        updateExpensesData={updateExpensesData}
        onFormSubmit={onFormSubmit}
        enumerations={enumerations}
        followUps={followUps}
        createdBy={data ? data.createdBy : undefined}
        createdByLegacy={data ? data.createdByLegacy : undefined}
      />
    </>
  );
};
