import {
  Title,
  Text,
  Button,
  Container,
  Group,
} from '@mantine/core';
import classes from './NotFoundPage.module.css';
import { Illustration } from './Illustration';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/');
  };

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>Stránka nebyla nalezena</Title>
          <Text c="#3b3a3a" size="lg" ta="center" className={classes.description} mt={20}>
            Je nám líto, požadovaná stránka bohužel nebyla nalezena. Pravděpodobně byla odstraněna,
            přemístěna nebo přejmenována. Případně byla nesprávně zadána adresa.
          </Text>
          <Group justify="center" mt={30}>
            <Button size="md" bg={'dpkk-blue'} onClick={handleButtonClick}>Vraťte mě na domovskou stránku</Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}