import UserRole from '../auth/UserRole';

export const translateRole = (role: string) => {
  switch (role) {
    case UserRole.SYSADMIN:
      return 'Správce databáze';
    case UserRole.MANAGER:
      return 'Správce uživatelských účtů';
    case UserRole.SUPERUSER:
      return 'Super uživatel';
    case UserRole.USER:
      return 'Uživatel z Krajského úřadu Karlovarského kraje';
    case UserRole.EXTERNAL_USER:
      return 'Externí uživatel';
    default:
      return role;
  }
};
