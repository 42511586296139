import { Select } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';
import classes from '../../../core/style/PrintStyles.module.css';

interface CustomSelectProps {
  label: string;
  inputProps: any;
  required?: boolean;
  disabled?: boolean;
  allowDeselect?: boolean;
  data?: any[];
}

export function CustomSelect({ label, inputProps, required, disabled, allowDeselect, data }: CustomSelectProps) {
  return (
    <Select
      {...inputProps}
      label={label}
      withAsterisk={required}
      disabled={disabled}
      allowDeselect={allowDeselect}
      id={'print-input-field'}
      data={data}
      mt={10}
      radius={10}
      classNames={{
        section: classes.section,
      }}
      leftSection={<IconChevronDown/>}
      rightSection={<IconChevronDown/>}
    />
  );
}
