import { Button } from '@mantine/core';
import React from 'react';

interface CustomButtonProps {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
}

export function CustomSubmitButton({ label, loading, disabled }: CustomButtonProps) {
  return (
    <Button
      type="submit"
      color={'dpkk-blue'}
      variant="filled"
      disabled={disabled}
      radius={10}
      loading={loading}>
      {label}
    </Button>
  );
}
