/* tslint:disable */
/* eslint-disable */
/**
 * Non-oAuth Scopes example
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.11
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ColumnFilterDto
 */
export interface ColumnFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ColumnFilterDto
     */
    'id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ColumnFilterDto
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface EmailNotificationRequestDto
 */
export interface EmailNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationRequestDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationRequestDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailNotificationRequestDto
     */
    'sendToAll'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailNotificationRequestDto
     */
    'recipientKeycloakIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EnumerationDto
 */
export interface EnumerationDto {
    /**
     * 
     * @type {number}
     * @memberof EnumerationDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnumerationDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumerationDto
     */
    'translation'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumerationDto
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnumerationType = {
    HolderLegalForm: 'HOLDER_LEGAL_FORM',
    ProjectType: 'PROJECT_TYPE',
    ActionPlan: 'ACTION_PLAN',
    DevelopmentState: 'DEVELOPMENT_STATE',
    Initiative: 'INITIATIVE',
    Decision: 'DECISION',
    FundingMainSource: 'FUNDING_MAIN_SOURCE'
} as const;

export type EnumerationType = typeof EnumerationType[keyof typeof EnumerationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ExpensesType = {
    ConsiderableExpenses: 'CONSIDERABLE_EXPENSES',
    IneligibleExpenses: 'INELIGIBLE_EXPENSES',
    BudgetEntitlement: 'BUDGET_ENTITLEMENT',
    OperatingCosts: 'OPERATING_COSTS',
    SustainabilityCosts: 'SUSTAINABILITY_COSTS',
    SubsidyReturn: 'SUBSIDY_RETURN',
    ScheduledDepositsReceived: 'SCHEDULED_DEPOSITS_RECEIVED'
} as const;

export type ExpensesType = typeof ExpensesType[keyof typeof ExpensesType];


/**
 * 
 * @export
 * @interface FollowUpDto
 */
export interface FollowUpDto {
    /**
     * 
     * @type {number}
     * @memberof FollowUpDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUpDto
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof FollowUpDto
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface FollowUpsDto
 */
export interface FollowUpsDto {
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpPrkkPriorityArea'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpPrkkSubregion'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpPrkkSpecificTarget'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpPrkkMeasure'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpSrrcrStrategicTarget'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpSrrcrSpecificTarget'?: Array<FollowUpDto>;
    /**
     * 
     * @type {Array<FollowUpDto>}
     * @memberof FollowUpsDto
     */
    'followUpSrrcrTypeMeasure'?: Array<FollowUpDto>;
}
/**
 * 
 * @export
 * @interface GetProjectsExcelExportProjectExportDataParameter
 */
export interface GetProjectsExcelExportProjectExportDataParameter {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetProjectsExcelExportProjectExportDataParameter
     */
    'projectIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectsExcelExportProjectExportDataParameter
     */
    'exportAll'?: boolean;
}
/**
 * 
 * @export
 * @interface GetProjectsOverviewProjectOverviewFiltersParameter
 */
export interface GetProjectsOverviewProjectOverviewFiltersParameter {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectsOverviewProjectOverviewFiltersParameter
     */
    'filterMyProjects'?: boolean;
    /**
     * 
     * @type {Array<ProjectOverviewFilterDto>}
     * @memberof GetProjectsOverviewProjectOverviewFiltersParameter
     */
    'overviewFilters'?: Array<ProjectOverviewFilterDto>;
}
/**
 * 
 * @export
 * @interface GetProjectsPaginationParameter
 */
export interface GetProjectsPaginationParameter {
    /**
     * 
     * @type {Array<PaginationDto>}
     * @memberof GetProjectsPaginationParameter
     */
    'sorting'?: Array<PaginationDto>;
    /**
     * 
     * @type {number}
     * @memberof GetProjectsPaginationParameter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectsPaginationParameter
     */
    'pageIndex'?: number;
}
/**
 * 
 * @export
 * @interface GetProjectsProjectFiltersParameter
 */
export interface GetProjectsProjectFiltersParameter {
    /**
     * 
     * @type {string}
     * @memberof GetProjectsProjectFiltersParameter
     */
    'globalFilter'?: string;
    /**
     * 
     * @type {Array<ColumnFilterDto>}
     * @memberof GetProjectsProjectFiltersParameter
     */
    'columnFilters'?: Array<ColumnFilterDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectsProjectFiltersParameter
     */
    'filterMyProjects'?: boolean;
}
/**
 * 
 * @export
 * @interface GetUsersPageUserFiltersParameter
 */
export interface GetUsersPageUserFiltersParameter {
    /**
     * 
     * @type {string}
     * @memberof GetUsersPageUserFiltersParameter
     */
    'globalFilter'?: string;
    /**
     * 
     * @type {Array<ColumnFilterDto>}
     * @memberof GetUsersPageUserFiltersParameter
     */
    'columnFilters'?: Array<ColumnFilterDto>;
}
/**
 * 
 * @export
 * @interface GlobalNotificationDto
 */
export interface GlobalNotificationDto {
    /**
     * 
     * @type {boolean}
     * @memberof GlobalNotificationDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotificationDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotificationDto
     */
    'displayFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotificationDto
     */
    'displayTo'?: string;
}
/**
 * 
 * @export
 * @interface IndicatorDto
 */
export interface IndicatorDto {
    /**
     * 
     * @type {number}
     * @memberof IndicatorDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IndicatorDto
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndicatorDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndicatorDto
     */
    'measuringUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndicatorDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface KeycloakUserDto
 */
export interface KeycloakUserDto {
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserDto
     */
    'keycloakId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserDto
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface PageableProjectsResponse
 */
export interface PageableProjectsResponse {
    /**
     * 
     * @type {number}
     * @memberof PageableProjectsResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableProjectsResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<ProjectResponseDto>}
     * @memberof PageableProjectsResponse
     */
    'data'?: Array<ProjectResponseDto>;
}
/**
 * 
 * @export
 * @interface PageableUserNotificationsResponse
 */
export interface PageableUserNotificationsResponse {
    /**
     * 
     * @type {number}
     * @memberof PageableUserNotificationsResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableUserNotificationsResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<ProjectNotificationResponseDto>}
     * @memberof PageableUserNotificationsResponse
     */
    'data'?: Array<ProjectNotificationResponseDto>;
}
/**
 * 
 * @export
 * @interface PageableUsersResponse
 */
export interface PageableUsersResponse {
    /**
     * 
     * @type {number}
     * @memberof PageableUsersResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableUsersResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<KeycloakUserDto>}
     * @memberof PageableUsersResponse
     */
    'data'?: Array<KeycloakUserDto>;
}
/**
 * 
 * @export
 * @interface PaginationDto
 */
export interface PaginationDto {
    /**
     * 
     * @type {string}
     * @memberof PaginationDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationDto
     */
    'desc'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectDatabaseError
 */
export interface ProjectDatabaseError {
    /**
     * 
     * @type {number}
     * @memberof ProjectDatabaseError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDatabaseError
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDatabaseError
     */
    'exception'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDatabaseError
     */
    'params'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectExpenseDto
 */
export interface ProjectExpenseDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectExpenseDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectExpenseDto
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectExpenseDto
     */
    'expense': number;
    /**
     * 
     * @type {ExpensesType}
     * @memberof ProjectExpenseDto
     */
    'expenseType'?: ExpensesType;
}


/**
 * 
 * @export
 * @interface ProjectIdsDto
 */
export interface ProjectIdsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectIdsDto
     */
    'ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ProjectNotificationRequestDto
 */
export interface ProjectNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectNotificationRequestDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotificationRequestDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ProjectNotificationResponseDto
 */
export interface ProjectNotificationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectNotificationResponseDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectNotificationResponseDto
     */
    'projectId'?: number;
    /**
     * 
     * @type {KeycloakUserDto}
     * @memberof ProjectNotificationResponseDto
     */
    'author'?: KeycloakUserDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotificationResponseDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotificationResponseDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotificationResponseDto
     */
    'created'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectNotificationResponseDto
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectOverviewFilterDto
 */
export interface ProjectOverviewFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewFilterDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ColumnFilterDto>}
     * @memberof ProjectOverviewFilterDto
     */
    'columnFilters'?: Array<ColumnFilterDto>;
}
/**
 * 
 * @export
 * @interface ProjectRequestDto
 */
export interface ProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'holderIc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'holder'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'holderLegalFormEnumerationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'eventNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'guarantor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'contactEmails'?: string;
    /**
     * 
     * @type {Array<IndicatorDto>}
     * @memberof ProjectRequestDto
     */
    'indicators'?: Array<IndicatorDto>;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'briefDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'relatedResolution'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'approval'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'totalCosts'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'resultQuantification'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'typeEnumerationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'partners'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'realizationPlace'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'actionPlanEnumerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'developmentStateEnumerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'initiativeEnumerationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'preprojectPreparationStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'implementationStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'implementationEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'implementationLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'financialEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'sustainabilityPeriodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'sustainabilityPeriodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'territorialDecisionEnumerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'buildingPermitEnumerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'totalEstimatedCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'preparationCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'implementationCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'fundingMainSourceEnumerationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpPrkkPriorityAreaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpPrkkSubregionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpPrkkSpecificTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpPrkkMeasureId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpSrrcrStrategicTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpSrrcrSpecificTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'followUpSrrcrTypeMeasureId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'financialPlan'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'keyWord'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'operationalProgram'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'priorityAxis'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'investmentPriority'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequestDto
     */
    'territorialDimension'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'european'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'stateBudget'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequestDto
     */
    'ownResources'?: number;
    /**
     * 
     * @type {Array<ProjectExpenseDto>}
     * @memberof ProjectRequestDto
     */
    'expenses'?: Array<ProjectExpenseDto>;
}
/**
 * 
 * @export
 * @interface ProjectResponseDto
 */
export interface ProjectResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'holderIc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'holder'?: string;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'holderLegalForm'?: EnumerationDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'eventNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'guarantor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'contactEmails'?: string;
    /**
     * 
     * @type {Array<IndicatorDto>}
     * @memberof ProjectResponseDto
     */
    'indicators'?: Array<IndicatorDto>;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'briefDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'relatedResolution'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'approval'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'totalCosts'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'resultQuantification'?: string;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'type'?: EnumerationDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'partners'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'realizationPlace'?: string;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'actionPlan'?: EnumerationDto;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'developmentState'?: EnumerationDto;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'initiative'?: EnumerationDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'preprojectPreparationStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'implementationStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'implementationEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'implementationLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'financialEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'sustainabilityPeriodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'sustainabilityPeriodEnd'?: string;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'territorialDecision'?: EnumerationDto;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'buildingPermit'?: EnumerationDto;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'totalEstimatedCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'preparationCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'implementationCosts'?: number;
    /**
     * 
     * @type {EnumerationDto}
     * @memberof ProjectResponseDto
     */
    'fundingMainSource'?: EnumerationDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpPrkkPriorityArea'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpPrkkSubregion'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpPrkkSpecificTarget'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpPrkkMeasure'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpSrrcrStrategicTarget'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpSrrcrSpecificTarget'?: FollowUpDto;
    /**
     * 
     * @type {FollowUpDto}
     * @memberof ProjectResponseDto
     */
    'followUpSrrcrTypeMeasure'?: FollowUpDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'financialPlan'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'keyWord'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'operationalProgram'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'priorityAxis'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'investmentPriority'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'territorialDimension'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'european'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'stateBudget'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponseDto
     */
    'ownResources'?: number;
    /**
     * 
     * @type {Array<ProjectExpenseDto>}
     * @memberof ProjectResponseDto
     */
    'expenses'?: Array<ProjectExpenseDto>;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'created'?: string;
    /**
     * 
     * @type {KeycloakUserDto}
     * @memberof ProjectResponseDto
     */
    'createdBy'?: KeycloakUserDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'createdByLegacy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'updated'?: string;
    /**
     * 
     * @type {KeycloakUserDto}
     * @memberof ProjectResponseDto
     */
    'updatedBy'?: KeycloakUserDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDto
     */
    'updatedByLegacy'?: string;
}
/**
 * 
 * @export
 * @interface ProjectsOverviewDto
 */
export interface ProjectsOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectsOverviewDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectsOverviewDto
     */
    'totalProjects'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectsOverviewDto
     */
    'totalFilteredProjects'?: number;
}
/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDto
     */
    'success': boolean;
}

/**
 * EnumerationsApi - axios parameter creator
 * @export
 */
export const EnumerationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all enumerations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumerations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/enumerations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumerationsApi - functional programming interface
 * @export
 */
export const EnumerationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumerationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all enumerations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnumerations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumerationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnumerations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnumerationsApi - factory interface
 * @export
 */
export const EnumerationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumerationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all enumerations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumerations(options?: any): AxiosPromise<Array<EnumerationDto>> {
            return localVarFp.getEnumerations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnumerationsApi - object-oriented interface
 * @export
 * @class EnumerationsApi
 * @extends {BaseAPI}
 */
export class EnumerationsApi extends BaseAPI {
    /**
     * 
     * @summary Get all enumerations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumerationsApi
     */
    public getEnumerations(options?: AxiosRequestConfig) {
        return EnumerationsApiFp(this.configuration).getEnumerations(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FollowUpApi - axios parameter creator
 * @export
 */
export const FollowUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all follow ups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowUps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/followups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowUpApi - functional programming interface
 * @export
 */
export const FollowUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FollowUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all follow ups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowUps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowUpsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowUps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FollowUpApi - factory interface
 * @export
 */
export const FollowUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FollowUpApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all follow ups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowUps(options?: any): AxiosPromise<FollowUpsDto> {
            return localVarFp.getFollowUps(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowUpApi - object-oriented interface
 * @export
 * @class FollowUpApi
 * @extends {BaseAPI}
 */
export class FollowUpApi extends BaseAPI {
    /**
     * 
     * @summary Get all follow ups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowUpApi
     */
    public getFollowUps(options?: AxiosRequestConfig) {
        return FollowUpApiFp(this.configuration).getFollowUps(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Save global notification
         * @summary Save global notification
         * @param {GlobalNotificationDto} globalNotificationDto Save global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGlobalNotification: async (globalNotificationDto: GlobalNotificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalNotificationDto' is not null or undefined
            assertParamExists('createGlobalNotification', 'globalNotificationDto', globalNotificationDto)
            const localVarPath = `/globalNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globalNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an email notification
         * @summary Send an email notification
         * @param {EmailNotificationRequestDto} emailNotificationRequestDto Send an email notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailNotification: async (emailNotificationRequestDto: EmailNotificationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailNotificationRequestDto' is not null or undefined
            assertParamExists('sendEmailNotification', 'emailNotificationRequestDto', emailNotificationRequestDto)
            const localVarPath = `/emailNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailNotificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Save global notification
         * @summary Save global notification
         * @param {GlobalNotificationDto} globalNotificationDto Save global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGlobalNotification(globalNotificationDto: GlobalNotificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGlobalNotification(globalNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an email notification
         * @summary Send an email notification
         * @param {EmailNotificationRequestDto} emailNotificationRequestDto Send an email notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailNotification(emailNotificationRequestDto: EmailNotificationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailNotification(emailNotificationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Save global notification
         * @summary Save global notification
         * @param {GlobalNotificationDto} globalNotificationDto Save global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGlobalNotification(globalNotificationDto: GlobalNotificationDto, options?: any): AxiosPromise<GlobalNotificationDto> {
            return localVarFp.createGlobalNotification(globalNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get global notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalNotification(options?: any): AxiosPromise<GlobalNotificationDto> {
            return localVarFp.getGlobalNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * Send an email notification
         * @summary Send an email notification
         * @param {EmailNotificationRequestDto} emailNotificationRequestDto Send an email notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailNotification(emailNotificationRequestDto: EmailNotificationRequestDto, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.sendEmailNotification(emailNotificationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Save global notification
     * @summary Save global notification
     * @param {GlobalNotificationDto} globalNotificationDto Save global notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createGlobalNotification(globalNotificationDto: GlobalNotificationDto, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createGlobalNotification(globalNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get global notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getGlobalNotification(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getGlobalNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an email notification
     * @summary Send an email notification
     * @param {EmailNotificationRequestDto} emailNotificationRequestDto Send an email notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendEmailNotification(emailNotificationRequestDto: EmailNotificationRequestDto, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).sendEmailNotification(emailNotificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectNotificationsApi - axios parameter creator
 * @export
 */
export const ProjectNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new project notification to the project by projectId
         * @summary Add a new project notification to the project by projectId
         * @param {number} projectId ID parameter
         * @param {ProjectNotificationRequestDto} projectNotificationRequestDto Create a new project notification for the project by projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectNotification: async (projectId: number, projectNotificationRequestDto: ProjectNotificationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createProjectNotification', 'projectId', projectId)
            // verify required parameter 'projectNotificationRequestDto' is not null or undefined
            assertParamExists('createProjectNotification', 'projectNotificationRequestDto', projectNotificationRequestDto)
            const localVarPath = `/projectnotifications/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectNotificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all project notifications by projectId
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectNotifications: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectNotifications', 'projectId', projectId)
            const localVarPath = `/projectnotifications/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all notifications for current user
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications: async (pagination?: GetProjectsPaginationParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark all notifications as read
         * @summary Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projectnotifications/markread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark all project notifications as read
         * @summary Mark all project notifications as read
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markProjectNotificationsAsRead: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('markProjectNotificationsAsRead', 'projectId', projectId)
            const localVarPath = `/projectnotifications/markread/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectNotificationsApi - functional programming interface
 * @export
 */
export const ProjectNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new project notification to the project by projectId
         * @summary Add a new project notification to the project by projectId
         * @param {number} projectId ID parameter
         * @param {ProjectNotificationRequestDto} projectNotificationRequestDto Create a new project notification for the project by projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectNotification(projectId: number, projectNotificationRequestDto: ProjectNotificationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectNotificationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectNotification(projectId, projectNotificationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all project notifications by projectId
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectNotifications(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectNotificationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectNotifications(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all notifications for current user
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotifications(pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableUserNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotifications(pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark all notifications as read
         * @summary Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationsAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationsAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark all project notifications as read
         * @summary Mark all project notifications as read
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markProjectNotificationsAsRead(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markProjectNotificationsAsRead(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectNotificationsApi - factory interface
 * @export
 */
export const ProjectNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectNotificationsApiFp(configuration)
    return {
        /**
         * Add a new project notification to the project by projectId
         * @summary Add a new project notification to the project by projectId
         * @param {number} projectId ID parameter
         * @param {ProjectNotificationRequestDto} projectNotificationRequestDto Create a new project notification for the project by projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectNotification(projectId: number, projectNotificationRequestDto: ProjectNotificationRequestDto, options?: any): AxiosPromise<ProjectNotificationResponseDto> {
            return localVarFp.createProjectNotification(projectId, projectNotificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all project notifications by projectId
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectNotifications(projectId: number, options?: any): AxiosPromise<Array<ProjectNotificationResponseDto>> {
            return localVarFp.getProjectNotifications(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all notifications for current user
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(pagination?: GetProjectsPaginationParameter, options?: any): AxiosPromise<PageableUserNotificationsResponse> {
            return localVarFp.getUserNotifications(pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark all notifications as read
         * @summary Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsRead(options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.markNotificationsAsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Mark all project notifications as read
         * @summary Mark all project notifications as read
         * @param {number} projectId ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markProjectNotificationsAsRead(projectId: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.markProjectNotificationsAsRead(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectNotificationsApi - object-oriented interface
 * @export
 * @class ProjectNotificationsApi
 * @extends {BaseAPI}
 */
export class ProjectNotificationsApi extends BaseAPI {
    /**
     * Add a new project notification to the project by projectId
     * @summary Add a new project notification to the project by projectId
     * @param {number} projectId ID parameter
     * @param {ProjectNotificationRequestDto} projectNotificationRequestDto Create a new project notification for the project by projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectNotificationsApi
     */
    public createProjectNotification(projectId: number, projectNotificationRequestDto: ProjectNotificationRequestDto, options?: AxiosRequestConfig) {
        return ProjectNotificationsApiFp(this.configuration).createProjectNotification(projectId, projectNotificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all project notifications by projectId
     * @param {number} projectId ID parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectNotificationsApi
     */
    public getProjectNotifications(projectId: number, options?: AxiosRequestConfig) {
        return ProjectNotificationsApiFp(this.configuration).getProjectNotifications(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all notifications for current user
     * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectNotificationsApi
     */
    public getUserNotifications(pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig) {
        return ProjectNotificationsApiFp(this.configuration).getUserNotifications(pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark all notifications as read
     * @summary Mark all notifications as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectNotificationsApi
     */
    public markNotificationsAsRead(options?: AxiosRequestConfig) {
        return ProjectNotificationsApiFp(this.configuration).markNotificationsAsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark all project notifications as read
     * @summary Mark all project notifications as read
     * @param {number} projectId ID parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectNotificationsApi
     */
    public markProjectNotificationsAsRead(projectId: number, options?: AxiosRequestConfig) {
        return ProjectNotificationsApiFp(this.configuration).markProjectNotificationsAsRead(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new project to the database
         * @summary Add a new project to the database
         * @param {ProjectRequestDto} projectRequestDto Create a new project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectRequestDto: ProjectRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectRequestDto' is not null or undefined
            assertParamExists('createProject', 'projectRequestDto', projectRequestDto)
            const localVarPath = `/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete multiple projects by their IDs.
         * @summary Delete projects by IDs
         * @param {ProjectIdsDto} projectIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects: async (projectIdsDto: ProjectIdsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdsDto' is not null or undefined
            assertParamExists('deleteProjects', 'projectIdsDto', projectIdsDto)
            const localVarPath = `/projects/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single project
         * @summary Find project by ID
         * @param {number} id ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectById', 'id', id)
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all projects with filters and pagination
         * @param {GetProjectsProjectFiltersParameter} [projectFilters] Project filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (projectFilters?: GetProjectsProjectFiltersParameter, pagination?: GetProjectsPaginationParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectFilters !== undefined) {
                localVarQueryParameter['projectFilters'] = projectFilters;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download CSV projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsCSVExport: async (projectExportData: GetProjectsExcelExportProjectExportDataParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectExportData' is not null or undefined
            assertParamExists('getProjectsCSVExport', 'projectExportData', projectExportData)
            const localVarPath = `/projects/exportcsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectExportData !== undefined) {
                localVarQueryParameter['projectExportData'] = projectExportData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Excel projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsExcelExport: async (projectExportData: GetProjectsExcelExportProjectExportDataParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectExportData' is not null or undefined
            assertParamExists('getProjectsExcelExport', 'projectExportData', projectExportData)
            const localVarPath = `/projects/exportexcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectExportData !== undefined) {
                localVarQueryParameter['projectExportData'] = projectExportData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects overview
         * @param {GetProjectsOverviewProjectOverviewFiltersParameter} [projectOverviewFilters] Project overview filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsOverview: async (projectOverviewFilters?: GetProjectsOverviewProjectOverviewFiltersParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectOverviewFilters !== undefined) {
                localVarQueryParameter['projectOverviewFilters'] = projectOverviewFilters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing project by ID
         * @summary Update an existing project
         * @param {number} id ID parameter
         * @param {ProjectRequestDto} projectRequestDto Update an existent project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: number, projectRequestDto: ProjectRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'projectRequestDto' is not null or undefined
            assertParamExists('updateProject', 'projectRequestDto', projectRequestDto)
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new project to the database
         * @summary Add a new project to the database
         * @param {ProjectRequestDto} projectRequestDto Create a new project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectRequestDto: ProjectRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete multiple projects by their IDs.
         * @summary Delete projects by IDs
         * @param {ProjectIdsDto} projectIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjects(projectIdsDto: ProjectIdsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjects(projectIdsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a single project
         * @summary Find project by ID
         * @param {number} id ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all projects with filters and pagination
         * @param {GetProjectsProjectFiltersParameter} [projectFilters] Project filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(projectFilters?: GetProjectsProjectFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(projectFilters, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download CSV projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsCSVExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsCSVExport(projectExportData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Excel projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsExcelExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsExcelExport(projectExportData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects overview
         * @param {GetProjectsOverviewProjectOverviewFiltersParameter} [projectOverviewFilters] Project overview filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsOverview(projectOverviewFilters?: GetProjectsOverviewProjectOverviewFiltersParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectsOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsOverview(projectOverviewFilters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing project by ID
         * @summary Update an existing project
         * @param {number} id ID parameter
         * @param {ProjectRequestDto} projectRequestDto Update an existent project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: number, projectRequestDto: ProjectRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, projectRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Add a new project to the database
         * @summary Add a new project to the database
         * @param {ProjectRequestDto} projectRequestDto Create a new project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectRequestDto: ProjectRequestDto, options?: any): AxiosPromise<ProjectResponseDto> {
            return localVarFp.createProject(projectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete multiple projects by their IDs.
         * @summary Delete projects by IDs
         * @param {ProjectIdsDto} projectIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects(projectIdsDto: ProjectIdsDto, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.deleteProjects(projectIdsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single project
         * @summary Find project by ID
         * @param {number} id ID parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id: number, options?: any): AxiosPromise<ProjectResponseDto> {
            return localVarFp.getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all projects with filters and pagination
         * @param {GetProjectsProjectFiltersParameter} [projectFilters] Project filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(projectFilters?: GetProjectsProjectFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: any): AxiosPromise<PageableProjectsResponse> {
            return localVarFp.getProjects(projectFilters, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download CSV projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsCSVExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: any): AxiosPromise<File> {
            return localVarFp.getProjectsCSVExport(projectExportData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Excel projects export
         * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsExcelExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: any): AxiosPromise<File> {
            return localVarFp.getProjectsExcelExport(projectExportData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects overview
         * @param {GetProjectsOverviewProjectOverviewFiltersParameter} [projectOverviewFilters] Project overview filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsOverview(projectOverviewFilters?: GetProjectsOverviewProjectOverviewFiltersParameter, options?: any): AxiosPromise<Array<ProjectsOverviewDto>> {
            return localVarFp.getProjectsOverview(projectOverviewFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing project by ID
         * @summary Update an existing project
         * @param {number} id ID parameter
         * @param {ProjectRequestDto} projectRequestDto Update an existent project in the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: number, projectRequestDto: ProjectRequestDto, options?: any): AxiosPromise<ProjectResponseDto> {
            return localVarFp.updateProject(id, projectRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Add a new project to the database
     * @summary Add a new project to the database
     * @param {ProjectRequestDto} projectRequestDto Create a new project in the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(projectRequestDto: ProjectRequestDto, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(projectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete multiple projects by their IDs.
     * @summary Delete projects by IDs
     * @param {ProjectIdsDto} projectIdsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjects(projectIdsDto: ProjectIdsDto, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjects(projectIdsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a single project
     * @summary Find project by ID
     * @param {number} id ID parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectById(id: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all projects with filters and pagination
     * @param {GetProjectsProjectFiltersParameter} [projectFilters] Project filters
     * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(projectFilters?: GetProjectsProjectFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(projectFilters, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download CSV projects export
     * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsCSVExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsCSVExport(projectExportData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Excel projects export
     * @param {GetProjectsExcelExportProjectExportDataParameter} projectExportData Project export data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsExcelExport(projectExportData: GetProjectsExcelExportProjectExportDataParameter, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsExcelExport(projectExportData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects overview
     * @param {GetProjectsOverviewProjectOverviewFiltersParameter} [projectOverviewFilters] Project overview filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsOverview(projectOverviewFilters?: GetProjectsOverviewProjectOverviewFiltersParameter, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsOverview(projectOverviewFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing project by ID
     * @summary Update an existing project
     * @param {number} id ID parameter
     * @param {ProjectRequestDto} projectRequestDto Update an existent project in the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(id: number, projectRequestDto: ProjectRequestDto, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(id, projectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users with filters and pagination
         * @param {GetUsersPageUserFiltersParameter} [userFilters] User filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersPage: async (userFilters?: GetUsersPageUserFiltersParameter, pagination?: GetProjectsPaginationParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usersPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userFilters !== undefined) {
                localVarQueryParameter['userFilters'] = userFilters;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KeycloakUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users with filters and pagination
         * @param {GetUsersPageUserFiltersParameter} [userFilters] User filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersPage(userFilters?: GetUsersPageUserFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersPage(userFilters, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<KeycloakUserDto>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users with filters and pagination
         * @param {GetUsersPageUserFiltersParameter} [userFilters] User filters
         * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersPage(userFilters?: GetUsersPageUserFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: any): AxiosPromise<PageableUsersResponse> {
            return localVarFp.getUsersPage(userFilters, pagination, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users with filters and pagination
     * @param {GetUsersPageUserFiltersParameter} [userFilters] User filters
     * @param {GetProjectsPaginationParameter} [pagination] Pagination with sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersPage(userFilters?: GetUsersPageUserFiltersParameter, pagination?: GetProjectsPaginationParameter, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersPage(userFilters, pagination, options).then((request) => request(this.axios, this.basePath));
    }
}



