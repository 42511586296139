import React, { useEffect, useState } from 'react';
import createTheme from '@mui/material/styles/createTheme';
import TablePagination from '@mui/material/TablePagination';
import { ThemeProvider, useTheme } from '@mui/material';
import { MaterialTableProps } from './MaterialTable.model';
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_CS } from 'material-react-table/locales/cs';
import { csCZ } from '@mui/material/locale';
import { CustomButton } from '../CustomButton/CustomButton';
import { Flex, Space } from '@mantine/core';
import Box from '@mui/material/Box';
import { IconFileTypeCsv, IconFileTypeXls, IconTrash } from '@tabler/icons-react';
import { CustomActionIcon } from '../CustomActionIcon/CustomActionIcon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ExportMenu, ExportMode } from './ExportMenu';

const MaterialTable = ({
  columns,
  tableData,
  onTableChange,
  handleExportRowsExcel,
  handleExportRowsCsv,
  deleteProjects,
  clearFilters,
  isLoading,
}: MaterialTableProps) => {
  const globalTheme = useTheme();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    if (onTableChange) {
      onTableChange({
        pagination,
        sorting,
        columnFilters,
        globalFilter,
      });
    }
  }, [pagination, sorting, columnFilters, globalFilter]);

  const clearTableFilters = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setSorting([]);
    setColumnFilters([]);
    setGlobalFilter('');
    setRowSelection({});
    if (clearFilters) {
      clearFilters();
    }
  };

  const theme = createTheme({
    palette: {
      mode: globalTheme.palette.mode,
      primary: {
        main: '#b8b3e1',
      },
      info: {
        main: '#b8b3e1',
      },
    },
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.MuiTableRow-root:hover td': {
              backgroundColor: 'rgba(240,239,250,0.97)',
            },
          },
        },
      },
    },
  }, csCZ);

  const materialTable = useMaterialReactTable({
    columns,
    data: tableData.data,
    state: { pagination, sorting, columnFilters, globalFilter, rowSelection, isLoading: isLoading },
    localization: MRT_Localization_CS,
    enableFilterMatchHighlighting: false,
    enablePagination: false,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      columnVisibility: {
        holderIc: false,
        holderLegalForm: false,
        contactEmails: false,
        briefDescription: false,
        relatedResolution: false,
        resultQuantification: false,
        partners: false,
        followUpPrkkPriorityArea: false,
        followUpSrrcrStrategicTarget: false,
        initiative: false,
        preprojectPreparationStart: false,
        implementationLength: false,
        financialEnd: false,
        sustainabilityPeriodStart: false,
        sustainabilityPeriodEnd: false,
        totalEstimatedCosts: false,
        preparationCosts: false,
        implementationCosts: false,
        fundingMainSource: false,
      },
    },
    // SORTING
    manualSorting: true,
    enableMultiSort: false,
    onSortingChange: setSorting,
    // COLUMN FILTERING
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    columnFilterDisplayMode: 'popover',
    // GLOBAL SEARCH FILTER
    enableGlobalFilter: true,
    onGlobalFilterChange: setGlobalFilter,
    // ROW SELECTION
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    positionToolbarAlertBanner: 'bottom',
    muiCircularProgressProps: {
      color: 'primary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    icons: {
      FilterAltIcon: (props: any) =>  <FilterAltIcon {...props} style={{ color: '#9a0b20' }} />,
    },
    // BUTTONS FOR EXPORT, IMPORT AND DELETE PROJECTS
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', pb: 2, mt: 2 }}>
        <ExportMenu
            label={'Exportovat vybrané do XSLX'}
            leftSection={<IconFileTypeXls size={26} />}
            actions={[
              {
                name: 'Exportovat vše',
                icon: '',
                count: tableData.totalElements,
                action: () => {
                  if (handleExportRowsExcel) {
                    handleExportRowsExcel(table.getSelectedRowModel().rows, ExportMode.ALL);
                  }
                },
              },
              {
                name: 'Exportovat vybrané',
                icon: '',
                count: table.getSelectedRowModel().rows.length,
                action: () => {
                  if (handleExportRowsExcel) {
                    handleExportRowsExcel(table.getSelectedRowModel().rows, ExportMode.SELECTED);
                  }
                },
                disabled: !table.getIsSomePageRowsSelected() && !table.getIsAllRowsSelected(),
              },
              {
                name: 'Exportovat vše na stránce',
                icon: '',
                count: tableData.data.length,
                action: () => {
                  if (handleExportRowsExcel) {
                    handleExportRowsExcel(table.getSelectedRowModel().rows, ExportMode.ALL_ON_PAGE);
                  }
                },
              },
            ]}
        />
        <ExportMenu
            label={'Exportovat vybrané do CSV'}
            leftSection={<IconFileTypeCsv size={26} />}
            actions={[
              {
                name: 'Exportovat vše',
                icon: '',
                count: tableData.totalElements,
                action: () => {
                  if (handleExportRowsCsv) {
                    handleExportRowsCsv(table.getSelectedRowModel().rows, ExportMode.ALL);
                  }
                },
              },
              {
                name: 'Exportovat vybrané',
                icon: '',
                count: table.getSelectedRowModel().rows.length,
                action: () => {
                  if (handleExportRowsCsv) {
                    handleExportRowsCsv(table.getSelectedRowModel().rows, ExportMode.SELECTED);
                  }
                },
                disabled: !table.getIsSomePageRowsSelected() && !table.getIsAllRowsSelected(),
              },
              {
                name: 'Exportovat vše na stránce',
                icon: '',
                count: tableData.data.length,
                action: () => {
                  if (handleExportRowsCsv) {
                    handleExportRowsCsv(table.getSelectedRowModel().rows, ExportMode.ALL_ON_PAGE);
                  }
                },
              },
            ]}
        />
        <Space w={'xl'}></Space>
        <CustomActionIcon
          variant="transparent"
          mt={10}
          mr={10}
          color={'#b01728'}
          badgeCount={table.getSelectedRowModel().rows.length}
          leftSection={<IconTrash size={26} />}
          disabled={!table.getIsSomePageRowsSelected() && !table.getIsAllRowsSelected()}
          tooltipText={'Smazat vybrané projekty'}
          onClick={() => {
            if (deleteProjects) {
              deleteProjects(table.getSelectedRowModel().rows);
            }
          }}
        ></CustomActionIcon>
      </Box>
    ),
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPagination({
      ...pagination,
      pageIndex: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPagination({
      pageIndex: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
        <Flex justify={'flex-end'}>
          <CustomButton variant={'transparent'} onClick={clearTableFilters} label={'Vymazat filtry'}></CustomButton>
        </Flex>
        <Space h="md"/>
        <MaterialReactTable
          table={materialTable}
        />
        <TablePagination
          component="div"
          count={tableData.totalElements}
          page={pagination.pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </LocalizationProvider>
      </ThemeProvider>
    </>

  );
};

export default MaterialTable;
