import React from 'react';
import { ProjectCardFormValues } from '../ProjectCardForm.model';
import { Grid, Text } from '@mantine/core';
import { CustomTextInput } from '../../CustomInputFields/CustomTextInput/CustomTextInput';
import { CustomNumberInput } from '../../CustomInputFields/CustomNumberInput/CustomNumberInput';
import { UseFormReturnType } from '@mantine/form';

interface IndicatorsFormProps {
  form: UseFormReturnType<ProjectCardFormValues>;
}

export const IndicatorsForm = ({
  form,
}: IndicatorsFormProps) => {
  const indicatorFields = form?.values?.indicators?.map((item, index) => {
    return (
      <div key={item.key}>
        {index === 0 ?
          <Text size="md" mt={20}>Indikátory</Text>
          : undefined}
        <Grid>
          <Grid.Col span={8}>
            <CustomTextInput
              label={index === 0 ? 'Indikátor' : ''}
              inputProps={form.getInputProps(`indicators.${index}.description`)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <CustomTextInput
              label={index === 0 ? 'Měrná jednotka' : ''}
              inputProps={form.getInputProps(`indicators.${index}.measuringUnit`)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <CustomNumberInput
              label={index === 0 ? 'Hodnota' : ''}
              inputProps={form.getInputProps(`indicators.${index}.value`)}
              rightSection={''}
            />
          </Grid.Col>
        </Grid>
      </div>
    );
  });

  return (
    <>
      {indicatorFields}
    </>
  );
};
