import {
  EnumerationDto,
  EnumerationType,
  FollowUpsDto,
  ProjectResponseDto,
} from '../../build/generated-sources/openapi';
import {
  stringDateTimeToFormattedString,
  formatNumberWithCurrency,
  stringDateToFormattedString,
  truncateString,
} from '../../core/util/Util';
import { MRT_Cell } from 'material-react-table';
import { Link } from 'react-router-dom';
import { UserSelectType } from './Projects.model';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mantine/core';

export const getProjectsColumns = (
  usersList: UserSelectType[],
  canSeeUsers: boolean,
  enumerations: EnumerationDto[],
  followUps: FollowUpsDto): any => {
  return [
    {
      accessorKey: 'registrationNumber',
      enableClickToCopy: true,
      header: 'Číslo projektu',
      size: 100,
    },
    {
      accessorFn: (row: ProjectResponseDto) => `CisloProjektu_${row.registrationNumber}`,
      header: 'Název',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: MRT_Cell<any> }, row: any) => {
        return (
          <Link to={`/projekty/${cell.row.original.id}`} relative="path">
            {`CisloProjektu_${cell.row.original.registrationNumber}`}
          </Link>
        );
      },
    },
    {
      accessorKey: 'name',
      enableClickToCopy: true,
      header: 'Název projektu',
    },
    {
      accessorKey: 'holder',
      enableClickToCopy: true,
      header: 'Název nositele projektu',
    },
    {
      accessorKey: 'holderIc',
      enableClickToCopy: true,
      header: 'IČ Nositele',
    },
    {
      accessorKey: 'holderLegalForm',
      enableClickToCopy: true,
      header: 'Právní forma nositele',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.HolderLegalForm)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'createdBy',
      accessorFn: (row: ProjectResponseDto) => row,
      header: 'Autor',
      enableColumnFilter: canSeeUsers,
      filterSelectOptions: usersList,
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<ProjectResponseDto> }) => {
        const project = (cell.getValue() as ProjectResponseDto);
        if (project.createdBy) {
          return project.createdBy.firstName + ' ' + project.createdBy.lastName;
        } else if (project.createdByLegacy) {
          return project.createdByLegacy;
        }
        return '';
      },
    },
    {
      accessorKey: 'updatedBy',
      accessorFn: (row: ProjectResponseDto) => row,
      header: 'Autor změny',
      enableColumnFilter: canSeeUsers,
      filterSelectOptions: usersList,
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<ProjectResponseDto> }) => {
        const project = (cell.getValue() as ProjectResponseDto);
        if (project.updatedBy) {
          return project.updatedBy.firstName + ' ' + project.updatedBy.lastName;
        } else if (project.updatedByLegacy) {
          return project.updatedByLegacy;
        }
        return '';
      },
    },
    {
      accessorKey: 'eventNumber',
      enableClickToCopy: true,
      header: 'Číslo akce',
    },
    {
      accessorKey: 'guarantor',
      enableClickToCopy: true,
      header: 'Garant projektu',
    },
    {
      accessorKey: 'contactPerson',
      enableClickToCopy: true,
      header: 'Kontaktní osoba/tel',
    },
    {
      accessorKey: 'contactEmails',
      enableClickToCopy: true,
      header: 'Kontaktní email',
      Cell: ({ cell }: { cell: MRT_Cell<ProjectResponseDto> }) => {
        if (!cell.getValue()) {
          return '';
        }
        const emails = (cell.getValue() as string).split(';').map(value => ({ value }));
        return (<>
          { emails && emails.length != 0 && emails.map((email, index) => {
            return <Typography key={index} variant={'body2'}>{truncateString(email.value)}</Typography>;
          },
          )}
        </>);
      },
    },
    {
      accessorKey: 'manager',
      enableClickToCopy: true,
      header: 'Vedoucí projektu',
    },
    {
      accessorKey: 'briefDescription',
      columnResizeDirection: 'rtl',
      header: 'Stručný popis projektu',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return <Tooltip multiline w={220} color={'dpkk-blue'} label={cell.getValue() as string}>
          <Typography variant={'body2'}>{truncateString(cell.getValue() as string, 30)}</Typography>
        </Tooltip>;
      },
    },
    {
      accessorKey: 'relatedResolution',
      enableClickToCopy: true,
      header: 'Usnesení související s projektem',
    },
    {
      accessorKey: 'approval',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.approval)}`,
      enableClickToCopy: true,
      header: 'Schválení závazného finančního příslibu',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      accessorKey: 'totalCosts',
      enableClickToCopy: true,
      header: 'Celkové náklady dle finančního příslibu',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return formatNumberWithCurrency(cell.getValue() as number);
      },
    },
    {
      accessorKey: 'result',
      enableClickToCopy: true,
      header: 'Výsledek/výstup projektu',
    },
    {
      accessorKey: 'resultQuantification',
      enableClickToCopy: true,
      header: 'Kvantifikace výsledku',
    },
    {
      accessorKey: 'type',
      enableClickToCopy: true,
      header: 'Typ projektu',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.ProjectType)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'partners',
      enableClickToCopy: true,
      header: 'Spolupracující subjekt',
    },
    {
      accessorKey: 'realizationPlace',
      enableClickToCopy: true,
      header: 'Místo realizace',
    },
    {
      accessorKey: 'followUpPrkkPriorityArea',
      accessorFn: (row: ProjectResponseDto) => row,
      header: 'Návaznost na PR KK',
      filterSelectOptions: followUps?.followUpPrkkPriorityArea ? followUps?.followUpPrkkPriorityArea
        .map(entry => {
          return ({ value: entry.id + '', label: entry.title });
        }) : [],
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<ProjectResponseDto> }) => {
        const project = (cell.getValue() as ProjectResponseDto);
        return (<>
          { project.followUpPrkkPriorityArea && (
            <Typography variant={'body2'}>{truncateString(project?.followUpPrkkPriorityArea?.title)}</Typography>
          )}
          { project.followUpPrkkSubregion && (
            <Typography variant={'body2'}>{truncateString(project?.followUpPrkkSubregion?.title)}</Typography>
          )}
          { project.followUpPrkkSpecificTarget && (
            <Typography variant={'body2'}>{truncateString(project?.followUpPrkkSpecificTarget?.title)}</Typography>
          )}
          { project.followUpPrkkMeasure && (
            <Typography variant={'body2'}>{truncateString(project?.followUpPrkkMeasure?.title)}</Typography>
          )}
        </>);
      },
    },
    {
      accessorKey: 'followUpSrrcrStrategicTarget',
      accessorFn: (row: ProjectResponseDto) => row,
      header: 'Návaznost na SRR ČR',
      filterSelectOptions: followUps?.followUpSrrcrStrategicTarget ? followUps?.followUpSrrcrStrategicTarget
        .map(entry => {
          return ({ value: entry.id + '', label: entry.title });
        }) : [],
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<ProjectResponseDto> }) => {
        const project = (cell.getValue() as ProjectResponseDto);
        return (<>
          {project.followUpSrrcrStrategicTarget && (
            <Typography variant={'body2'}>{truncateString(project?.followUpSrrcrStrategicTarget?.title)}</Typography>
          )}
          {project.followUpSrrcrSpecificTarget && (
            <Typography variant={'body2'}>{truncateString(project?.followUpSrrcrSpecificTarget?.title)}</Typography>
          )}
          {project.followUpSrrcrTypeMeasure && (
            <Typography variant={'body2'}>{truncateString(project?.followUpSrrcrTypeMeasure?.title)}</Typography>
          )}
        </>);
      },
    },
    {
      accessorKey: 'actionPlan',
      header: 'Akční plány',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.ActionPlan)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'developmentState',
      header: 'Stav rozpracovanosti navrhovaného projektu',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.DevelopmentState)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'initiative',
      header: 'OP nebo iniciativa',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Initiative)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      // datepicker
      accessorKey: 'preprojectPreparationStart',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.preprojectPreparationStart)}`,
      header: 'Zahájení předprojektové přípravy',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      // datepicker
      accessorKey: 'implementationStart',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.implementationStart)}`,
      header: 'Zahájení realizace',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      // datepicker
      accessorKey: 'implementationEnd',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.implementationEnd)}`,
      enableClickToCopy: true,
      header: 'Ukončení realizace',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      accessorKey: 'implementationLength',
      enableClickToCopy: true,
      header: 'Délka fyzické realizace - v měsících',
    },
    {
      // datepicker
      accessorKey: 'financialEnd',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.financialEnd)}`,
      header: 'Finanční ukončení projektu',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      // datepicker
      accessorKey: 'updated',
      accessorFn: (row: ProjectResponseDto) => `${stringDateTimeToFormattedString(row.updated)}`,
      enableClickToCopy: true,
      header: 'Změněno',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      // datepicker
      accessorKey: 'sustainabilityPeriodStart',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.sustainabilityPeriodStart)}`,
      header: 'Zahajeni doby udrzitelnosti',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      // datepicker
      accessorKey: 'sustainabilityPeriodEnd',
      accessorFn: (row: ProjectResponseDto) => `${stringDateToFormattedString(row.sustainabilityPeriodStart)}`,
      header: 'Ukončení doby udrzitelnosti',
      filterVariant: 'date-range',
      muiFilterDatePickerProps: {
        timezone: 'Europe/Prague',
      },
    },
    {
      accessorKey: 'territorialDecision',
      header: 'Uzemni rozhodnuti',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Decision)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'buildingPermit',
      header: 'Stavebni povoleni',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.Decision)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
    {
      accessorKey: 'totalEstimatedCosts',
      enableClickToCopy: true,
      header: 'Celkové (předpokládané) náklady projektu',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return formatNumberWithCurrency(cell.getValue() as number);
      },
    },
    {
      accessorKey: 'preparationCosts',
      enableClickToCopy: true,
      header: 'Náklady na přípravu projektu',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return formatNumberWithCurrency(cell.getValue() as number);
      },
    },
    {
      accessorKey: 'implementationCosts',
      enableClickToCopy: true,
      header: 'Náklady na realizaci projektu',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return formatNumberWithCurrency(cell.getValue() as number);
      },
    },
    {
      accessorKey: 'fundingMainSource',
      header: 'Předpokládaný hlavní zdroj financování',
      filterSelectOptions: enumerations?.filter((enumeration) => enumeration.type === EnumerationType.FundingMainSource)
        .map(entry => {
          return ({ value: entry.name, label: entry.translation });
        }),
      filterVariant: 'select',
      Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
        return (cell.getValue() as EnumerationDto)?.translation;
      },
    },
  ];
};
