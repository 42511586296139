import { useContext, useRef, useState } from 'react';
import { NavLink, Divider, Space } from '@mantine/core';
import {
  IconBooks, IconPlus, IconSettings, IconStar, IconUsers,
} from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import UserRole from '../../core/auth/UserRole';
import { AuthContext } from '../../core/auth/AuthContextProvider';

const navbarLinks = [
  {
    id: 'projects',
    label: 'Všechny projekty',
    links: ['/projekty', '/'],
    leftSection: <IconBooks size="1.3rem" stroke={1.5}/>,
    roles: [UserRole.SYSADMIN, UserRole.MANAGER, UserRole.SUPERUSER, UserRole.USER],
  },
  {
    id: 'myprojects',
    label: 'Moje projekty',
    links: ['/moje-projekty'],
    leftSection: <IconStar size="1.3rem" stroke={1.5}/>,
    roles: Object.values(UserRole),
  },
  {
    id: 'newproject',
    label: 'Nový projekt',
    links: ['/projekty/novy'],
    leftSection: <IconPlus size="1.3rem" stroke={1.5}/>,
    roles: Object.values(UserRole),
  },
  {
    id: 'users',
    label: 'Uživatele',
    links: ['/uzivatele'],
    leftSection: <IconUsers size="1.3rem" stroke={1.5}/>,
    roles: [UserRole.SYSADMIN, UserRole.MANAGER],
  },
  {
    id: 'notificationssettings',
    label: 'Nastavení oznámení',
    links: ['/nastaveni'],
    leftSection: <IconSettings size="1.3rem" stroke={1.5}/>,
    roles: [UserRole.SYSADMIN, UserRole.MANAGER],
  },
];

export function Navbar() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [active, setActive] = useState<number | null>(null);
  const ref = useRef<HTMLAnchorElement>(null);

  const determineActiveNavLink = () => {
    for (const [index, item] of navbarLinks.entries()) {
      if (item.links.indexOf(location.pathname) > -1) {
        setActive(index);
        return;
      }
    }
    setActive(null);
  };

  React.useEffect(() => {
    determineActiveNavLink();
  }, [location.pathname]);

  const items = navbarLinks.map((item, index) => {
    if (!authContext.hasRole(item.roles)) {
      return;
    }
    return (
      <React.Fragment key={item.id}>
        {
          item.id === 'users' && <Divider my="sm"/>
        }
        {
          item.id === 'newproject' && <Space my="sm"/>
        }
        <NavLink
          component={Link}
          ref={ref}
          to={item.links[0]}
          active={index === active}
          label={item.label}
          leftSection={item.leftSection}
          onClick={() => setActive(index)}
          color="dpkk-blue"
        />
      </React.Fragment>
    );
  });

  return (
    <nav>
      <div>
        {items}
      </div>
    </nav>
  );
}
