import React, { useEffect, useState } from 'react';
import { ExpensesTotal, ProjectCardFormProps } from '../ProjectCardForm.model';
import { Box, Center, Divider, Grid, Space } from '@mantine/core';
import classes from '../ProjectCardForm.module.css';
import { CustomText } from '../../CustomText/CustomText';
import { CustomTextInput } from '../../CustomInputFields/CustomTextInput/CustomTextInput';
import { CustomNumberInput } from '../../CustomInputFields/CustomNumberInput/CustomNumberInput';
import { ExpensesType } from '../../../build/generated-sources/openapi';
import { CustomNumberInputWithValue } from '../../CustomInputFields/CustomNumberInput/CustomNumberInputWithValue';
import { NotificationType, showNotification } from '../../../core/notifications/Notifications';

export const FinancialPlanForm = ({
  form,
  combinedExpenses,
  updateCombinedExpenses,
  updateExpensesData,
}: ProjectCardFormProps) => {
  const [expensesTotal, setExpensesTotal] = useState<ExpensesTotal>({
    considerableExpensesTotal: 0,
    ineligibleExpensesTotal: 0,
    budgetEntitlementTotal: 0,
    operatingCostsTotal: 0,
    sustainabilityCostsTotal: 0,
    subsidyReturnTotal: 0,
    scheduledDepositsReceivedTotal: 0,
    totalExpenses: 0,
    totalBudgetEntitlement: 0,
    europeanTotal: 0,
    stateBudgetTotal: 0,
    ownResourcesTotal: 0,
    resourcesBeyondScopeTotal: 0,
    fundingBreakdownTotal: 0,
  });

  useEffect(() => {
    const expenseTypeTotal = {
      CONSIDERABLE_EXPENSES: 0,
      INELIGIBLE_EXPENSES: 0,
      BUDGET_ENTITLEMENT: 0,
      OPERATING_COSTS: 0,
      SUSTAINABILITY_COSTS: 0,
      SUBSIDY_RETURN: 0,
      SCHEDULED_DEPOSITS_RECEIVED: 0,
    };

    combinedExpenses.forEach((yearData) => {
      yearData.forEach((expenseData) => {
        const { expenseType, expense } = expenseData;
        if (expenseType) {
          expenseTypeTotal[expenseType] += expense;
        }
      });
    });

    const updatedExpensesTotal = {
      considerableExpensesTotal: expenseTypeTotal[ExpensesType.ConsiderableExpenses],
      ineligibleExpensesTotal: expenseTypeTotal[ExpensesType.IneligibleExpenses],
      budgetEntitlementTotal: expenseTypeTotal[ExpensesType.BudgetEntitlement],
      operatingCostsTotal: expenseTypeTotal[ExpensesType.OperatingCosts],
      sustainabilityCostsTotal: expenseTypeTotal[ExpensesType.SustainabilityCosts],
      subsidyReturnTotal: expenseTypeTotal[ExpensesType.SubsidyReturn],
      scheduledDepositsReceivedTotal: expenseTypeTotal[ExpensesType.ScheduledDepositsReceived],
      totalExpenses: expenseTypeTotal[ExpensesType.ConsiderableExpenses] + expenseTypeTotal[ExpensesType.IneligibleExpenses],
      totalBudgetEntitlement: expenseTypeTotal[ExpensesType.IneligibleExpenses]
        + expenseTypeTotal[ExpensesType.BudgetEntitlement],
      europeanTotal: (((form.values.european ?? 0) / 100) * expenseTypeTotal[ExpensesType.ConsiderableExpenses]) || 0,
      stateBudgetTotal: (((form.values.stateBudget ?? 0) / 100) * expenseTypeTotal[ExpensesType.ConsiderableExpenses]) || 0,
      ownResourcesTotal: (((form.values.ownResources ?? 0) / 100) * expenseTypeTotal[ExpensesType.ConsiderableExpenses]) || 0,
      resourcesBeyondScopeTotal: expenseTypeTotal[ExpensesType.IneligibleExpenses],
      fundingBreakdownTotal: 0,
    };

    updatedExpensesTotal.fundingBreakdownTotal = updatedExpensesTotal.europeanTotal
      + updatedExpensesTotal.stateBudgetTotal
      + updatedExpensesTotal.ownResourcesTotal
      + updatedExpensesTotal.resourcesBeyondScopeTotal;

    setExpensesTotal(updatedExpensesTotal);
    updateExpensesData(expensesTotal);
  }, [combinedExpenses]);

  const handleFormChange = (event: any, year: number, expensesType: string) => {
    const yearData = combinedExpenses.get(year);

    if (!yearData) {
      showNotification('Při načítání finančních údajů došlo k problému', 'financial-data', NotificationType.ERROR);
      return;
    }

    const yearDataCopy = [...yearData];
    const expenseIndex = yearDataCopy.findIndex(
      (expense) => expense.expenseType === expensesType,
    );

    if (expenseIndex !== -1) {
      yearDataCopy[expenseIndex] = {
        ...yearDataCopy[expenseIndex],
        expense: event,
      };
      updateCombinedExpenses(year, yearDataCopy);
    }
  };

  return (
    <>
      <Box className={classes['inner-box']}>
        <Grid grow>
          <Grid.Col span={8}>
            <Space h={'xl'}></Space>
            <CustomNumberInputWithValue
              label="Celkové výdaje"
              disabled
              inline
              value={expensesTotal.considerableExpensesTotal + expensesTotal.ineligibleExpensesTotal}
            />
            <Grid>
              <Grid.Col span={3} offset={2}>
                <Center>
                  <CustomText label={'uznatelné výdaje'}/>
                </Center>
              </Grid.Col>
              <Grid.Col span={3}>
                <Center>
                  <CustomText label={'neuznatelné výdaje'}/>
                </Center>
              </Grid.Col>
              <Grid.Col span={3}>
                <Center>
                  <CustomText label={'nárok na rozpočet'}/>
                </Center>
              </Grid.Col>
            </Grid>
            {Array.from(combinedExpenses).map(([year, yearData]) => (
              <div key={year}>
                <Grid key={year}>
                  <Grid.Col span={1}>
                    <CustomText label={year.toString()} mt={15}/>
                  </Grid.Col>
                  <Grid.Col span={3} offset={1}>
                    <CustomNumberInputWithValue
                      name={`yearData.${year}.CONSIDERABLE_EXPENSES`}
                      onChange={event => handleFormChange(event, year, 'CONSIDERABLE_EXPENSES')}
                      value={yearData.find((expense) => expense.expenseType === 'CONSIDERABLE_EXPENSES')?.expense}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <CustomNumberInputWithValue
                      name={`yearData.${year}.INELIGIBLE_EXPENSES`}
                      onChange={event => handleFormChange(event, year, 'INELIGIBLE_EXPENSES')}
                      value={yearData.find((expense) => expense.expenseType === 'INELIGIBLE_EXPENSES')?.expense}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <CustomNumberInputWithValue
                      name={`yearData.${year}.BUDGET_ENTITLEMENT`}
                      onChange={event => handleFormChange(event, year, 'BUDGET_ENTITLEMENT')}
                      value={yearData.find((expense) => expense.expenseType === 'BUDGET_ENTITLEMENT')?.expense}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}/>
                </Grid>
              </div>
            ))}
            <Grid>
              <Grid.Col span={1}>
                <CustomText label={'Celkem'} mt={15}/>
              </Grid.Col>
              <Grid.Col span={3} offset={1}>
                <CustomNumberInputWithValue
                  disabled
                  value={expensesTotal.considerableExpensesTotal}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  disabled
                  value={expensesTotal.ineligibleExpensesTotal}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  disabled
                  value={expensesTotal.budgetEntitlementTotal}
                />
              </Grid.Col>
              <Grid.Col span={1}/>
            </Grid>
            <Grid>
              <Grid.Col span={8}>
                <CustomText label={'Nárok na rozpočet celkem (uznatelné + neuznatelné) :'} mt={15}/>
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  disabled
                  value={expensesTotal.totalBudgetEntitlement}
                />
              </Grid.Col>
              <Grid.Col span={1}/>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomTextInput
              label="Operační program"
              inputProps={form.getInputProps('operationalProgram')}
            />
            <CustomTextInput
              label="Prioritní osa"
              inputProps={form.getInputProps('priorityAxis')}
            />
            <CustomTextInput
              label="Investiční priorita"
              inputProps={form.getInputProps('investmentPriority')}
            />
            <CustomTextInput
              label="Územní dimenze"
              inputProps={form.getInputProps('territorialDimension')}
            />
            <CustomText label={'Rozpad financování'}/>
            <div className={classes.printHidden}>
              <Grid columns={17}>
                <Grid.Col span={4} offset={1}>
                  <CustomText mt={15}
                              label="Evropská"></CustomText>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInput
                    rightSection={'%'}
                    width={80}
                    inputProps={form.getInputProps('european')}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInputWithValue
                    disabled
                    value={expensesTotal.europeanTotal}
                  />
                </Grid.Col>
                <Grid.Col span={4}/>
                <Grid.Col span={4} offset={1}>
                  <CustomText mt={15}
                              label="Státní rozpočet"></CustomText>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInput
                    rightSection={'%'}
                    width={80}
                    inputProps={form.getInputProps('stateBudget')}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInputWithValue
                    disabled
                    value={expensesTotal.stateBudgetTotal}
                  />
                </Grid.Col>
                <Grid.Col span={4}/>
                <Grid.Col span={4} offset={1}>
                  <CustomText mt={15}
                              label="Vlastní zdroje"></CustomText>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInput
                    rightSection={'%'}
                    width={80}
                    inputProps={form.getInputProps('ownResources')}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInputWithValue
                    disabled
                    value={expensesTotal.ownResourcesTotal}
                  />
                </Grid.Col>
              </Grid>
              <Grid columns={6}>
                <Grid.Col span={2}>
                  <CustomText mt={15}
                              label="Zdroje nad rámec projektu a kritérií"></CustomText>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInputWithValue
                    disabled
                    value={expensesTotal.resourcesBeyondScopeTotal}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CustomText mt={15}
                              label="Celkem"></CustomText>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomNumberInputWithValue
                    disabled
                    value={expensesTotal.fundingBreakdownTotal}
                  />
                </Grid.Col>
              </Grid>
            </div>
            <div className={classes.formHidden}>
              <CustomText mt={15}
                          label="Evropská"></CustomText>
              <CustomNumberInput
                rightSection={'%'}
                width={80}
                inputProps={form.getInputProps('european')}
              />
              <CustomNumberInputWithValue
                disabled
                value={expensesTotal.europeanTotal}
              />
              <CustomText mt={15}
                          label="Státní rozpočet"></CustomText>
              <CustomNumberInput
                rightSection={'%'}
                width={80}
                inputProps={form.getInputProps('stateBudget')}
              />
              <CustomNumberInputWithValue
                disabled
                value={expensesTotal.stateBudgetTotal}
              />
              <CustomText mt={15}
                          label="Vlastní zdroje"></CustomText>
              <CustomNumberInput
                rightSection={'%'}
                width={80}
                inputProps={form.getInputProps('ownResources')}
              />
              <CustomNumberInputWithValue
                disabled
                value={expensesTotal.ownResourcesTotal}
              />
              <CustomText mt={15}
                          label="Zdroje nad rámec projektu a kritérií"></CustomText>
              <CustomNumberInputWithValue
                disabled
                value={expensesTotal.resourcesBeyondScopeTotal}
              />
              <CustomText mt={15}
                          label="Celkem"></CustomText>
              <CustomNumberInputWithValue
                disabled
                value={expensesTotal.fundingBreakdownTotal}
              />
            </div>
          </Grid.Col>
        </Grid>

        <Divider my="md" color={'black'}/>
        <div className={classes.pageBreak}/>

        <Grid columns={13}>
          <Grid.Col span={3} offset={1}>
            <Center>
              <CustomText label={'Provozní náklady'} mt={15}/>
            </Center>
          </Grid.Col>
          <Grid.Col span={3}>
            <Center>
              <CustomText label={'Náklady na udržitelnost'} mt={15}/>
            </Center>
          </Grid.Col>
          <Grid.Col span={3}>
            <Center>
              <CustomText label={'Návratnost dotace'} mt={15}/>
            </Center>
          </Grid.Col>
          <Grid.Col span={3}>
            <Center>
              <CustomText label={'Plánované přijaté zálohy'} mt={15}/>
            </Center>
          </Grid.Col>
        </Grid>
        {Array.from(combinedExpenses).map(([year, yearData]) => (
          <div key={year}>
            <Grid key={year} columns={13}>
              <Grid.Col span={1}>
                <CustomText label={year.toString()} mt={15}/>
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  name={`yearData.${year}.OPERATING_COSTS`}
                  onChange={event => handleFormChange(event, year, 'OPERATING_COSTS')}
                  value={yearData.find((expense) => expense.expenseType === 'OPERATING_COSTS')?.expense}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  name={`yearData.${year}.SUSTAINABILITY_COSTS`}
                  onChange={event => handleFormChange(event, year, 'SUSTAINABILITY_COSTS')}
                  value={yearData.find((expense) => expense.expenseType === 'SUSTAINABILITY_COSTS')?.expense}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  name={`yearData.${year}.SUBSIDY_RETURN`}
                  onChange={event => handleFormChange(event, year, 'SUBSIDY_RETURN')}
                  value={yearData.find((expense) => expense.expenseType === 'SUBSIDY_RETURN')?.expense}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CustomNumberInputWithValue
                  name={`yearData.${year}.SCHEDULED_DEPOSITS_RECEIVED`}
                  onChange={event => handleFormChange(event, year, 'SCHEDULED_DEPOSITS_RECEIVED')}
                  value={yearData.find((expense) => expense.expenseType === 'SCHEDULED_DEPOSITS_RECEIVED')?.expense}
                />
              </Grid.Col>
            </Grid>
          </div>
        ))}
        <Grid columns={13}>
          <Grid.Col span={3} offset={1}>
            <CustomNumberInputWithValue
              label={'celkem'}
              disabled
              value={expensesTotal.operatingCostsTotal}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <CustomNumberInputWithValue
              label={'celkem'}
              disabled
              value={expensesTotal.sustainabilityCostsTotal}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <CustomNumberInputWithValue
              label={'celkem'}
              disabled
              value={expensesTotal.subsidyReturnTotal}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <CustomNumberInputWithValue
              label={'celkem'}
              disabled
              value={expensesTotal.scheduledDepositsReceivedTotal}
            />
          </Grid.Col>
        </Grid>

        <Divider my="md" color={'black'}/>
      </Box>
      <div className={classes.pageBreak}/>
    </>
  );
};
