import { useRouteError } from 'react-router-dom';
import classes from './ErrorPage.module.css';
import { Button, Container, Group, Text, Title } from '@mantine/core';

export default function ErrorPage() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <Container>
        <Title c="#3b3a3a" className={classes.title}>Něco se nepovedlo...</Title>
        <Text mt={20} c="#3b3a3a" size="lg" ta="center" className={classes.description}>
          Omlouváme se, naše servery nebyly schopny zpracovat vaši žádost. Zkuste obnovit stránku.
        </Text>
        <Group mt={30} justify="center">
          <Button bg={'dpkk-blue'} size="md" onClick={handleRefresh}>
            Obnovit stránku
          </Button>
        </Group>
      </Container>
    </div>
  );
}