import { MultiSelect } from '@mantine/core';
import React from 'react';

interface CustomMultiSelectProps {
  label: string;
  inputProps: any;
  required?: boolean;
  disabled?: boolean;
  data?: any[];
}

export function CustomMultiSelect({ label, inputProps, required, disabled, data }: CustomMultiSelectProps) {
  return (
    <MultiSelect
      {...inputProps}
      label={label}
      withAsterisk={required}
      disabled={disabled}
      data={data}
      mt={10}
      radius={10}
      clearable
      searchable
    />
  );
}
