import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../core/auth/AuthContextProvider';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Burger, Flex, Group, Loader } from '@mantine/core';
import { LogoIcon } from './Logo/Logo';
import { UserMenu } from './UserMenu/UserMenu';
import { Navbar } from './Navbar/Navbar';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/cs';
import { NotificationCentre } from './NotificationCentre/NotificationCentre';
import { GlobalNotificationCard } from './NotificationsSettingsForm/GlobalNotificationCard';
import { SupportModal } from './SupportModal/SupportModal';

export const ProtectedLayout = () => {
  const authContext = useContext(AuthContext);
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: { base: 70, md: 70, lg: 80 } }}
      navbar={{
        width: { base: 200, md: 300, lg: 300 },
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <Notifications/>
      <AppShell.Header>
        <Group justify="space-between">
          <Group>
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>
            <LogoIcon size={140}/>
          </Group>
          {authContext.isAuthenticated ? (
            <Flex>
              <NotificationCentre></NotificationCentre>
              <UserMenu></UserMenu>
            </Flex>
          ) : undefined}
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <AppShell.Section grow my="md">
          <Navbar></Navbar>
        </AppShell.Section>
        <AppShell.Section>
          <SupportModal></SupportModal>
        </AppShell.Section>

      </AppShell.Navbar>
      <AppShell.Main>
        {authContext.isAuthenticated ? (
          <DatesProvider settings={{ locale: 'cs', firstDayOfWeek: 1, weekendDays: [0, 6], timezone: 'Europe/Prague' }}>
            <>
              <GlobalNotificationCard></GlobalNotificationCard>
              <Outlet/>
            </>
          </DatesProvider>
        ) : (
          <Loader>
          </Loader>
        )}</AppShell.Main>
    </AppShell>
  );
};
