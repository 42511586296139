import React, { forwardRef, useContext } from 'react';
import { Menu, Text, rem, UnstyledButton, Group, Button, Flex } from '@mantine/core';
import {
  IconLogout,
} from '@tabler/icons-react';
import { AuthContext } from '../../core/auth/AuthContextProvider';
import Avatar from 'react-avatar';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  firstName: string;
  lastName: string;
  email: string;
  icon?: React.ReactNode;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ firstName, lastName, email, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      style={{
        padding: 'var(--mantine-spacing-md)',
        color: 'var(--mantine-color-text)',
        borderRadius: 'var(--mantine-radius-sm)',
      }}
      {...others}
    >
      <Group>
        <Avatar name={firstName + ' ' + lastName} color={'#312783'} round={true} size={'50px'}
                textSizeRatio={2}/>

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {firstName + ' ' + lastName}
          </Text>

          <Text c="dimmed" size="xs">
            {email}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ),
);

export function UserMenu() {
  const authContext = useContext(AuthContext);
  return (
    <div>
      <Menu shadow="md" width={200} trigger="hover" openDelay={100} closeDelay={400}>
        <Menu.Target>
          <UserButton
            firstName={authContext.userProfile.firstName ?? ''}
            lastName={authContext.userProfile.lastName ?? ''}
            email={authContext.userProfile.email ?? ''}
          />
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            color="red"
            leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }}/>}
            onClick={authContext.logout}
          >
            Odhlášení
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
